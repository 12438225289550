import { TimesheetType } from "../../../Models/TimesheetType";
import { TimesheetForm } from "../../../Models/TimesheetForm";
import { getCalendarInfoDaysFromMonday } from "../../../Helpers/DateHelper";
import { CalendarDay } from "../../../Models/CalendarInfo";
import {
  lowerCaseFirstChar,
  formatMoney,
} from "../../../Helpers/StringHelpers";

export const renderTimesheetTypeOptionsForWeek = (
  $container: JQuery<HTMLElement>,
  wageType: string,
  timesheetType: TimesheetType,
  formState: TimesheetForm
) => {
  const $formGroup = createFormGroup();

  const normalQtyDefault = timesheetType.NormalQtyDefault?.toString() ?? "";
  const normalRateDefault = timesheetType.NormalRateDefault?.toString() ?? "";

  let groupLabel = "";

  // Determine correct label for form group row
  if (
    timesheetType.NormalQtyAccess !== "H" &&
    timesheetType.NormalRateAccess !== "H"
  ) {
    groupLabel = "Normal Quantity";
  } else if (timesheetType.NormalRateAccess !== "H") {
    groupLabel = "Normal Rate";
  } else if (timesheetType.NormalQtyAccess !== "H") {
    groupLabel = "Hours";
  }

  $formGroup.append(
    `<label class="col-lg-2 control-label">${groupLabel} :</label>`
  );

  $formGroup.append(`
    <div class="col-lg-10">
      <div class="flip-scroll hours-type" data-validationTarget>
        <table class="table flip-content" style="border:1px solid #ddd;">
          <thead class="flip-content bordered-darkorange">
            <tr>
              <th class="text-center">Mon</th>
              <th class="text-center">Tue</th>
              <th class="text-center">Wed</th>
              <th class="text-center">Thu</th>
              <th class="text-center">Fri</th>
              <th class="text-center">Sat</th>
              <th class="text-center">Sun</th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
    </div>`);

  const $tableBody = $formGroup.find("tbody");

  const days = getCalendarInfoDaysFromMonday();

  const addToDays = (callback: (day: CalendarDay) => string) => {
    const $tr = $("<tr></tr>").appendTo($tableBody);
    days.forEach((day) => $tr.append($(callback(day))));
  };

  if (timesheetType.NormalQtyAccess !== "H") {
    addToDays((day) =>
      createTableCell(
        lowerCaseFirstChar(day) + "NormalQty",
        formState.WeekData?.[day]?.NormalQty?.toString() ?? normalQtyDefault,
        "Normal Qty",
        timesheetType.NormalQtyAccess === "R"
      )
    );
  }

  if (timesheetType.ExtraQtyAccess !== "H" && wageType !== "S") {
    addToDays((day) =>
      createTableCell(
        lowerCaseFirstChar(day) + "ExtraQty",
        formState.WeekData?.[day]?.ExtraQty?.toString() ?? "",
        "Overtime Qty",
        timesheetType.ExtraQtyAccess === "R"
      )
    );
  }

  if (timesheetType.OvertimeQtyAccess !== "H" && wageType !== "S") {
    addToDays((day) =>
      createTableCell(
        lowerCaseFirstChar(day) + "OvertimeQty",
        formState.WeekData?.[day]?.OvertimeQty?.toString() ?? "",
        "Double Time Qty",
        timesheetType.OvertimeQtyAccess === "R"
      )
    );
  }

  if (timesheetType.PublicHolidayQtyAccess !== "H" && wageType !== "S") {
    addToDays((day) =>
      createTableCell(
        lowerCaseFirstChar(day) + "PublicHolidayQty",
        formState.WeekData?.[day]?.PublicHolidayQty?.toString() ?? "",
        "Public Holiday Qty",
        timesheetType.PublicHolidayQtyAccess === "R"
      )
    );
  }

  if (timesheetType.NormalRateAccess !== "H") {
    addToDays((day) =>
      createTableCell(
        lowerCaseFirstChar(day) + "NormalRate",
        formState.WeekData?.[day]?.NormalRate?.toString() ?? normalRateDefault,
        "Normal Rate",
        timesheetType.NormalRateAccess === "R"
      )
    );
  }

  if (
    timesheetType.NormalRateAccess !== "H" &&
    timesheetType.NormalQtyAccess !== "H"
  ) {
    addToDays((day) => {
      const value =
        (formState.WeekData?.[day]?.NormalQty ?? 0) *
        (formState.WeekData?.[day]?.NormalRate ?? 0);
      return createTableCell(
        lowerCaseFirstChar(day) + "Value",
        formatMoney(value),
        "Value",
        true
      );
    });
  }

  $container.append($formGroup);
};

const createFormGroup = () => $('<div class="form-group"></div>');

const createTableCell = (
  name: string,
  value: string,
  placeholder: string = "",
  disabled: boolean = false
) =>
  `<td>
    <input type="text"
          aria-label="Enter hours for ${name}"
          placeholder="${placeholder}"
          title="${placeholder}"
          class="form-control"
          name="${name}"
          value="${value}"
          ${disabled ? "disabled" : ""} />
  </td>`;
