import { Store } from "../Helpers/Store";
import { RootState } from "../Models/RootState";
import {
  addCommonViewModelToState,
  addTimesheetInfoForPeriodToState,
} from "../Helpers/StateHelper";
import { TimesheetPeriodStatus } from "../Models/TimesheetPeriodStatus";
import { TimesheetPeriodStatusCode } from "../Models/CommonViewModel";
import { TimesheetApi } from "./TimesheetApi";
import appInsights from "../ApplicationInsights";

export class CalendarService {
  constructor(private store: Store<RootState>, private api: TimesheetApi) {}

  async moveToPrevWeek() {
    const state = this.store.getStateOrThrow();

    const currentDate = state.CalendarInfo.SelectedDate;
    const employeeCode = state.CalendarInfo.EmployeeCode;
    const wageType = state.CalendarInfo.WageType;

    try {
      const result = await this.api.showPreviousTimesheet(
        currentDate,
        employeeCode,
        { showLoader: true }
      );
      this.store.setState((state) =>
        addTimesheetInfoForPeriodToState(state, result)
      );
    } catch (e) {
      console.error(e as any);
      toastr.error((e as any).Status ?? "An unexpected error occurred.");
      appInsights.trackException({
        error: e as any,
      });
    }
  }

  async moveToNextWeek() {
    const state = this.store.getStateOrThrow();

    const currentDate = state.CalendarInfo.SelectedDate;
    const employeeCode = state.CalendarInfo.EmployeeCode;
    const wageType = state.CalendarInfo.WageType;

    try {
      const result = await this.api.showNextTimesheet(
        currentDate,
        employeeCode,
        { showLoader: true }
      );
      this.store.setState((state) =>
        addTimesheetInfoForPeriodToState(state, result)
      );
    } catch (e) {
      console.error(e);
      toastr.error((e as any).Status ?? "An unexpected error occurred.");
      appInsights.trackException({
        error: e as any,
      });
    }
  }

  async moveToWeek(date: Date) {
    const state = this.store.getStateOrThrow();

    const employeeCode = state.CalendarInfo.EmployeeCode;

    try {
      const result = await this.api.showSelectedWeekTimesheet(
        date,
        employeeCode,
        { showLoader: true }
      );
      this.store.setState((state) =>
        addTimesheetInfoForPeriodToState(state, result)
      );
    } catch (e) {
      console.error(e as any);
      toastr.error((e as any).Status ?? "An unexpected error occurred.");
      appInsights.trackException({
        error: e as any,
      });
    }
  }

  async showEmployeeTimesheet(
    accountName: string,
    employeeCode: string,
    displayName: string
  ) {
    if (!employeeCode) {
      toastr.error("Invalid employee code.");
      return;
    }

    try {
      const result =
        await this.api.getSelectedEmployeeTimesheetDataByAccountName(
        accountName,
        employeeCode,
          undefined,
          { showLoader: true, useEmployeeSettings: false }
      );

      this.store.setState((state) =>
        addCommonViewModelToState(state, result, {
          EmployeeCode: employeeCode,
          AccountName: accountName,
          EmployeeDisplayName: displayName,
        })
      );
    } catch (e) {
      console.error(e);
      toastr.error((e as any).Status ?? "An unexpected error occurred.");
      appInsights.trackException({
        error: e as any,
      });
    }
  }

  async setCurrentPeriodStatus(newStatusCode: TimesheetPeriodStatusCode) {
    const state = this.store.getStateOrThrow();

    if (state.SessionInfo.EmployeeRole !== "P") {
      toastr.error("Only payroll users can perform this action.");
      return false;
    }

    try {
      const periodStatus: TimesheetPeriodStatus = {
        EmployeeCode: state.CalendarInfo.EmployeeCode,
        PeriodStartDate: state.CalendarInfo.StartDate.toISOString(),
        PeriodEndDate: state.CalendarInfo.EndDate.toISOString(),
        StatusCode: newStatusCode,
      };
      const result = await this.api.saveTimesheetPeriodStatus(periodStatus, {
        showLoader: true,
      });

      if (result.Failed) {
        toastr.error(result.Status ?? "An unexpected error occurred.");
        return false;
      }

      this.store.setState((state) => ({
        ...state,
        CalendarInfo: {
          ...state.CalendarInfo,
          TimesheetPeriodStatusCode: newStatusCode,
        },
      }));

      return result.Successful;
    } catch (e) {
      console.error(e);
      toastr.error((e as any).Status ?? "An unexpected error occurred.");
      appInsights.trackException({
        error: e as any,
      });

      return false;
    }
  }

  /**
   * Refreshes calendar with data from server.
   * @returns True if success, false if failure
   */
  async refresh() {
    const state = this.store.getStateOrThrow();

    const employeeCode = state.CalendarInfo.EmployeeCode;
    const selectedDate = state.CalendarInfo.SelectedDate;

    try {
      const result = await this.api.showSelectedWeekTimesheet(
        selectedDate,
        employeeCode,
        { showLoader: true }
      );
      this.store.setState((state) =>
        addTimesheetInfoForPeriodToState(state, result)
      );
    } catch (e) {
      console.error(e as any);

      toastr.error((e as any).Status ?? "An unexpected error occurred.");
      appInsights.trackException({
        error: e as any,
      });
      return false;
    }

    return true;
  }
}
