import { Timesheet } from "../Models/Timesheet";
import {
  findTimesheetTypeById,
  calculateTimesheetSumTotal,
  calculateTimesheetAmount,
} from "../Helpers/TimesheetTypeHelper";
import { TimesheetType } from "../Models/TimesheetType";

export function render(
  sourceTimesheets: Timesheet[],
  timesheetTypes: TimesheetType[],
  isMobile: boolean
) {
  let totalHours = 0;
  let totalDollar = 0;
  let leaveHours = 0;
  let currJobCode = "";
  let currCostCode = "";
  let prevJobCode: string | null = null;
  let prevCostCode: string | null = null;
  let title = "";
  let timesheetSummary = "";
  let costcodeSummary = "";
  let leaveSummary = "";
  let glSummary = "";

  // Clone of timesheets array that will be sorted
  const timesheets = [...sourceTimesheets];

  timesheets.sort((a, b) => {
    if (a.Code !== b.Code) return (a.Code ?? "") > (b.Code ?? "") ? 1 : -1;
    else return (a.CostCode ?? "") > (b.CostCode ?? "") ? 1 : -1;
  });

  timesheets.forEach((currentTimesheet, i) => {
    const timesheetType = findTimesheetTypeById(
      timesheetTypes,
      currentTimesheet.TimesheetTypeId
    );

    currJobCode = prevJobCode = currentTimesheet?.Code?.trim() ?? "";
    currCostCode = prevCostCode = currentTimesheet?.CostCode?.trim() ?? "";
    title = currentTimesheet.Title ?? "";

    totalHours = 0;
    totalDollar = 0;
    costcodeSummary = "";

    // Get the prev job code and prev cost code
    if (i > 0) {
      prevJobCode = timesheets[i - 1]?.Code?.trim() ?? null;
    }

    if (timesheetType?.IsLeave) {
      leaveHours += currentTimesheet.NormalQty;
    } else {
      if (leaveHours != 0) {
        if (isMobile) {
          leaveSummary =
            '</br><div class="bold margin-top-5 col-md-7 col-xs-6"><strong>Leave </strong></div><div class="margin-top-5 col-md-2 col-xs-4 text-right"><strong>' +
            leaveHours +
            ' hr(s)</strong></div><div class="margin-top-5 col-md-2 col-xs-2 text-right"><strong></strong></div>' +
            '<div class="border-bottom"></div>';
        } else {
          leaveSummary =
            '</br><div class="bold margin-top-5 col-md-8 col-xs-8"><strong>Leave </strong></div><div class="margin-top-5 col-md-2 col-xs-2 text-right"><strong>' +
            leaveHours +
            " hr(s)</strong></div>" +
            '<div class="border-bottom"></div>';
        }

        leaveHours = 0;
      }

      //if current and previous jobcode is same continue next iteration as its summary is bind previously
      if (currJobCode == prevJobCode && i > 0) {
        return;
      } else {
        //get all the records having jobcode as currJobCode
        const joblist = timesheets.filter(
          (x) => x?.Code?.trim() == currJobCode
        );

        joblist.forEach((jobTimesheet, j) => {
          currCostCode = prevCostCode = jobTimesheet?.CostCode?.trim() ?? "";

          if (j > 0) {
            prevCostCode = joblist[j - 1]?.CostCode?.trim() ?? null;
          }

          // if current and previous costcode is same continue next iteration as its summary is bind previously
          if (currCostCode == prevCostCode && j > 0) return;
          else {
            let costCodeHours = 0;
            let dollar = 0;

            // get all the records having costcode as currCostCode and calculate its hours and dollars
            const costList = joblist.filter(
              (x) => x?.CostCode?.trim() == currCostCode
            );

            costList.forEach((costListTimesheet) => {
              if (costListTimesheet.NormalRate) {
                dollar += calculateTimesheetAmount(costListTimesheet);
              } else {
                costCodeHours += calculateTimesheetSumTotal(costListTimesheet);
              }
            });

            totalHours += costCodeHours;
            totalDollar += dollar;

            if (!currCostCode)
              glSummary +=
                '</br><div class="bold margin-top-5 col-md-' +
                (isMobile ? 7 : 8) +
                " col-xs-" +
                (isMobile ? 6 : 8) +
                '"><strong>' +
                currJobCode +
                " - " +
                title +
                '</strong></div><div class="margin-top-5 col-md-2 col-xs-' +
                (isMobile ? 4 : 2) +
                ' text-right"><strong>' +
                (totalHours == 0 ? "" : totalHours.toFixed(2) + " hr(s)") +
                '</strong></div><div class="margin-top-5 col-md-2 col-xs-2 text-right"><strong>' +
                (totalDollar == 0 ? "" : " $" + totalDollar.toFixed(2)) +
                "</strong></div>" +
                '<div class="border-bottom"></div>';
            else
              costcodeSummary +=
                '<div class="col-md-' +
                (isMobile ? 7 : 8) +
                " col-xs-" +
                (isMobile ? 6 : 8) +
                '">' +
                currCostCode +
                '</div><div class="col-md-2 col-xs-' +
                (isMobile ? 4 : 2) +
                ' text-right">' +
                (costCodeHours == 0
                  ? ""
                  : costCodeHours.toFixed(2) + " hr(s)") +
                ' </div><div class="col-md-2 col-xs-2 text-right">' +
                (dollar == 0 ? "" : " $" + dollar.toFixed(2)) +
                " </div>" +
                '<div class="border-bottom"></div>';
          }
        });

        if (currCostCode)
          timesheetSummary +=
            '</br><div class="bold margin-top-5 col-md-' +
            (isMobile ? 7 : 8) +
            " col-xs-" +
            (isMobile ? 6 : 8) +
            '"><strong>' +
            currJobCode +
            " - " +
            title +
            '</strong></div><div class="margin-top-5 col-md-2 col-xs-' +
            (isMobile ? 4 : 2) +
            ' text-right"><strong>' +
            (totalHours == 0 ? "" : totalHours.toFixed(2) + " hr(s)") +
            '</strong></div><div class="margin-top-5 col-md-2 col-xs-2 text-right"><strong>' +
            (totalDollar == 0 ? "" : " $" + totalDollar.toFixed(2)) +
            "</strong></div>" +
            '<div class="border-bottom"></div>' +
            costcodeSummary;
      }
    }
  });

  // bind leave summary
  if (leaveHours != 0) {
    leaveSummary =
      '<div class="bold margin-top-5 col-md-' +
      (isMobile ? 7 : 8) +
      " col-xs-" +
      (isMobile ? 6 : 8) +
      '"><strong>Leave </strong></div><div class="margin-top-5 col-md-2 col-xs-' +
      (isMobile ? "4 no-padding" : 2) +
      ' text-right"><strong>' +
      leaveHours +
      " hr(s)</strong></div>" +
      (isMobile
        ? '<div class="margin-top-5 col-md-2 col-xs-2 text-right"><strong></strong></div>'
        : "") +
      '<div class="border-bottom"></div>';
  }

  timesheetSummary += glSummary + leaveSummary;

  $("#divTimesheetSummary").html(timesheetSummary);
}
