import isEqual from "lodash.isequal";
import { Store } from "../../../Helpers/Store";
import { RootState } from "../../../Models/RootState";
import {
  createSingleCallLimiter,
  isCancelled,
} from "../../../Helpers/ApiHelper";
import { setFormState } from "../../../Helpers/StateHelper";
import { TimesheetForm, validCodeInfo } from "../../../Models/TimesheetForm";
import { emptyToNull } from "../../../Helpers/StringHelpers";
import {
  getWorkCodeInfo,
  getCachedWorkCodeInfo,
} from "../../../Helpers/WorkCodeInfo";
import { TimesheetApi } from "../../../Services/TimesheetApi";
import appInsights from "../../../ApplicationInsights";

/**
 * Calls API and populates SelectedCodeInfo property when Code is changed.
 * @param api TimesheetApi
 * @param store Store
 * @param prevState Previous State
 * @param state Current State
 */
export const createMiddleware = (
  api: TimesheetApi,
  store: Store<RootState>
) => {
  const lastOnly = createSingleCallLimiter();

  store.registerMiddleware(async (prevState, state, next) => {
    // Only continue if certain changes are detected
    if (
      !state.Forms.Timesheet?.Code ||
      !state.Forms.Timesheet?.TimesheetTypeId ||
      (prevState?.Forms.Timesheet?.Code === state.Forms.Timesheet?.Code &&
        prevState?.Forms.Timesheet?.TimesheetTypeId ===
          state.Forms.Timesheet?.TimesheetTypeId)
    ) {
      return next(state);
    }

    // Update store
    next(
      setFormState<TimesheetForm>("Timesheet", state, {
        SelectedCodeInfo: undefined,
        IsSelectedCodeInfoLoading: true,
      })
    );

    // Continue after store update to fetch details
    const code = state.Forms.Timesheet.Code;
    const defaultCostCode = !state.Forms.Timesheet.RecordId
      ? emptyToNull(state.SessionInfo.DefaultTSCostCode)
      : null;
    const costCode =
      emptyToNull(state.Forms.Timesheet.CostCode) ?? defaultCostCode;
    const timesheetTypeId = state.Forms.Timesheet.TimesheetTypeId;

    if (!code || !timesheetTypeId) return;

    const cachedResult = getCachedWorkCodeInfo(
      state.CalendarInfo.AccountName,
      code,
      timesheetTypeId
    );

    // If existing cost code is available use it as default
    if (cachedResult) {
      const costCodeOk =
        validCodeInfo(cachedResult) &&
        cachedResult.CostCodes.find((cc) => cc.Code === costCode);

      store.setState((state) =>
        setFormState<TimesheetForm>("Timesheet", state, {
          Code: code,
          CostCode: costCodeOk ? costCode : null,
          SelectedCodeInfo: cachedResult,
          Title:
            costCodeOk && validCodeInfo(cachedResult) ? cachedResult.Title : "",
          IsSelectedCodeInfoLoading: false,
        })
      );
    }

    // Then we continue looking up the latest version
    try {
      const result = await lastOnly((cancellationToken) =>
        getWorkCodeInfo(
          api,
          state.CalendarInfo.AccountName,
          code,
          timesheetTypeId,
          { cancellationToken },
          false
        )
      );

      if (isCancelled(result)) return;

      const costCodeOk =
        validCodeInfo(result) &&
        result.CostCodes.find((cc) => cc.Code === costCode);

      if (isEqual(result, cachedResult)) return;

      store.setState((state) =>
        setFormState<TimesheetForm>("Timesheet", state, {
          Code: code,
          CostCode: costCodeOk ? costCode : null,
          SelectedCodeInfo: result,
          Title: costCodeOk && validCodeInfo(result) ? result.Title : "",
          IsSelectedCodeInfoLoading: false,
        })
      );
    } catch (e) {
      appInsights.trackException({
        error: e as any,
      });
      store.setState((state) =>
        setFormState<TimesheetForm>("Timesheet", state, {
          Code: code,
          CostCode: null,
          SelectedCodeInfo: {
            Code: 666,
            Description: (e as any)?.message ?? "Unknown error",
            Location: "Local.CodeInfoMiddleware",
            RecordIdentifier: "",
            Type: "E",
          },
          Title: "",
          IsSelectedCodeInfoLoading: false,
        })
      );
    }
  });
};
