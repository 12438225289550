import { TimesheetPeriodStatusCode } from "../Models/CommonViewModel";

export const isTimesheetPeriodLocked = (
  statusCode: TimesheetPeriodStatusCode
) => {
  return statusCode === "P" || statusCode === "C";
};

/**
 * Returns the most-restrictive of two status codes.
 * @param code1
 * @param code2
 */
export const mergeStatusCodes = (
  code1: TimesheetPeriodStatusCode,
  code2: TimesheetPeriodStatusCode
) => {
  const statusCodeOrder: TimesheetPeriodStatusCode[] = ["E", "C", "P"];

  const code1Idx = statusCodeOrder.indexOf(code1);
  const code2Idx = statusCodeOrder.indexOf(code2);

  return statusCodeOrder[Math.max(code1Idx, code2Idx, 0)];
};
