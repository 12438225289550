import { html } from "../Helpers/TemplateTags";
import { CalendarInfo } from "../Models/CalendarInfo";
import { TimesheetType } from "../Models/TimesheetType";
import {
  upperCaseFirstChar,
  formatTotal,
  formatMoney,
} from "../Helpers/StringHelpers";
import { getShortMonthName } from "../Helpers/DateHelper";
import {
  calculateTotalHoursByDayAndType,
  TotalByType,
} from "../Helpers/TimesheetTypeHelper";
import { Timesheet } from "../Models/Timesheet";
import { SessionInfo } from "../Models/SessionInfo";
import { TimesheetPeriodStatusCode } from "../Models/CommonViewModel";
import { render as renderCalendarCard } from "./CalendarCard";
import { render as renderTimesheetPeriodStatus } from "./TimesheetPeriod";
import { isTimesheetPeriodLocked } from "../Helpers/TimesheetPeriodHelper";

export function render(
  sessionInfo: SessionInfo,
  calendarInfo: CalendarInfo,
  timesheetTypes: TimesheetType[],
  isMobile: boolean,
  firstRender: boolean
) {
  // Remove previously rendered timesheet cards
  $("#divCalendar").find(".widget").remove();

  // Render date on each day of week
  renderDayDateInfo("#spMonday", calendarInfo.CurrentPeriodDays.Monday);
  renderDayDateInfo("#spTuesday", calendarInfo.CurrentPeriodDays.Tuesday);
  renderDayDateInfo("#spWednesday", calendarInfo.CurrentPeriodDays.Wednesday);
  renderDayDateInfo("#spThursday", calendarInfo.CurrentPeriodDays.Thursday);
  renderDayDateInfo("#spFriday", calendarInfo.CurrentPeriodDays.Friday);
  renderDayDateInfo("#spSaturday", calendarInfo.CurrentPeriodDays.Saturday);
  renderDayDateInfo("#spSunday", calendarInfo.CurrentPeriodDays.Sunday);

  updateDayPlusButtonStatus(calendarInfo.TimesheetPeriodStatusCode);

  // Render daily hour summary
  renderDailySummary(timesheetTypes, calendarInfo.Timesheets);

  // Render all timesheet cards
  calendarInfo.Timesheets.forEach((timesheet) => {
    renderCalendarCard(timesheet, timesheetTypes, calendarInfo);
  });

  // Update is processed tick
  renderTimesheetPeriodStatus(
    calendarInfo.TimesheetPeriodStatusCode,
    sessionInfo.EmployeeRole
  );

  if (isMobile && firstRender) {
    $(".nav-tabs").scrollingTabs({});
  } else if (!isMobile) {
    equalizeHeadings();
  }
}

function renderDailySummary(
  timesheetTypes: TimesheetType[],
  timesheets: Timesheet[]
) {
  const totals = calculateTotalHoursByDayAndType(
    timesheetTypes,
    timesheets
  ) as Record<string, TotalByType>;

  Object.keys(totals).forEach((key) => {
    const dailyTotal = totals[key];
    const elDayKey = upperCaseFirstChar(key);

    let summary = formatTotal(dailyTotal.sumTotal);

    if (
      dailyTotal.normal +
        dailyTotal.extra +
        dailyTotal.overtime +
        dailyTotal.publicHoliday +
        dailyTotal.leave >
      0
    ) {
      summary += " (";
      summary += dailyTotal.normal
        ? ` <strong class="palegreen">${formatTotal(
            dailyTotal.normal,
            true
          )}</strong>`
        : "";
      summary += dailyTotal.extra
        ? ` <strong class="storm-cloud">${formatTotal(
            dailyTotal.extra,
            true
          )}</strong>`
        : "";
      summary += dailyTotal.overtime
        ? ` <strong class="orange">${formatTotal(
            dailyTotal.overtime,
            true
          )}</strong>`
        : "";
      summary += dailyTotal.publicHoliday
        ? ` <strong class="danger">${formatTotal(
            dailyTotal.publicHoliday,
            true
          )}</strong>`
        : "";
      summary += dailyTotal.leave
        ? ` <strong class="greenyellow">${formatTotal(
            dailyTotal.leave,
            true
          )}</strong>`
        : "";
      summary += " )";
    }

    if (dailyTotal.allowance)
      summary += ` <strong class="blue">| ${formatMoney(
        dailyTotal.allowance
      )}</strong>`;

    $(`#lbl${elDayKey}Hours`).html(summary).attr('aria-label', elDayKey + " Daily Hours " + summary); 
  });
}

function equalizeHeadings() {
  // Reset height as per the data in card header
  let maxHeight = -1;

  $(".day-card-header").css("height", "");

  // Get max height
  $(".day-card-header").each(function () {
    maxHeight = Math.max(maxHeight, $(this).height() ?? 0);
  });

  // Set all headers to the same height
  $(".day-card-header").each(function () {
    $(this).height(maxHeight);
  });
}

function renderDayDateInfo(selector: string, date: Date) {
  const $el = $(selector);

  if (!$el.length) {
    console.log("Failed to find selector " + selector);
    return;
  }

  const str = html`${date.getDate()}
    <small class="margin-left-5">
      <sup aria-label="${getShortMonthName(date.getMonth())}">${getShortMonthName(date.getMonth())}</sup>
    </small>`;

  $el.html(str);
}

/**
 * Disables add entry button (+) in the day header
 * if the timesheet period is closed.
 * @param timesheetPeriodStatus Current status.
 */
function updateDayPlusButtonStatus(
  timesheetPeriodStatus: TimesheetPeriodStatusCode
) {
  $("[data-addTimesheetToDay]").toggleClass(
    "disabled",
    isTimesheetPeriodLocked(timesheetPeriodStatus)
  );
}
