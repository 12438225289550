import { TimesheetType } from "../Models/TimesheetType";
import { WorkCode } from "../Models/WorkCode";

/**
 * Generates warnings that will be displayed when saving
 * a timesheet entry and on the timesheet entry card.
 *
 * Warnings in this context are basically validation issues
 * that aren't fatal errors and can be dismissed.
 * @param employeeBusinessUnitCode
 * @param codeInfo
 * @param costCode
 * @param timesheetType
 */
export const generateWarningsForTimesheetRecord = (
  employeeBusinessUnitCode: string,
  codeInfo: WorkCode,
  costCode: string | null,
  timesheetType: TimesheetType
): string[] => {
  let warnings: string[] = [];

  /**
   * Check 1) Ensure that employee and timesheet entry companies match.
   */
  const employeeCompany = getCompanyCodeFromBusinessUnitCode(
    employeeBusinessUnitCode
  );
  const timesheetCompany = getCompanyCodeFromBusinessUnitCode(
    codeInfo.BusinessUnitCode
  );

  if (
    employeeCompany !== timesheetCompany &&
    !(
      isSageGroupCompany(employeeCompany) &&
      isSageGroupCompany(timesheetCompany)
    )
  ) {
    warnings.push("This item will be posted outside your company.");
  }

  /**
   * Check 2) Ensure that entry is using a labour code if it is a time entry.
   */
  if (timesheetType?.NormalRateAccess === "H" && !timesheetType?.IsLeave) {
    if (codeInfo.CostTypeCode && codeInfo.CostTypeCode !== "L") {
      warnings.push("This item will be posted to a non-labour GL code.");
    } else if (costCode) {
      const costCodeInfo = codeInfo.CostCodes.find((cc) => cc.Code == costCode);

      if (costCodeInfo && costCodeInfo.TypeCode != "L") {
        warnings.push("This item will be posted to a non-labour code.");
      }
    }
  }

  return warnings;
};

/**
 * The first character of the BusinessUnitCode represents
 * the company code.
 * @param businessUnitCode
 */
const getCompanyCodeFromBusinessUnitCode = (businessUnitCode: string) => {
  return businessUnitCode[0];
};

/**
 * SAGE Group companies are from 1-7
 * @param company Company code
 */
const isSageGroupCompany = (company: string) => {
  const companyNum = +company;
  return companyNum ? companyNum >= 1 && companyNum <= 7 : false;
};
