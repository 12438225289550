import { TimesheetTemplate } from "../Models/TimesheetTemplate";
import { TimesheetForm } from "../Models/TimesheetForm";
import { Timesheet } from "../Models/Timesheet";

export const findTimesheetTemplateByTimesheetEntry = (
  timesheetTemplates: TimesheetTemplate[],
  timesheet: Timesheet | TimesheetForm
) => {
  return timesheetTemplates.find((timesheetTemplate) => {
    if (
      timesheetTemplate.TimesheetTypeId == timesheet.TimesheetTypeId &&
      timesheetTemplate.Code == timesheet.Code &&
      timesheetTemplate.Title == timesheet.Title &&
      (timesheetTemplate.CostCode == timesheet.CostCode ||
        //general ledger code
        timesheetTemplate.CostCode == null)
    ) {
      if (
        (timesheetTemplate.Description
          ? timesheetTemplate.Description.trim()
          : "") === (timesheet.Description ? timesheet.Description.trim() : "")
      ) {
        return true;
      }
    }

    return false;
  });
};
