import { Store } from "../Helpers/Store";
import { RootState } from "../Models/RootState";
import {
  removeTimesheetEntry,
  initialTimesheetFormState,
  timesheetFormStateFromTimesheetRecord,
  setFormState,
  createTimesheetRecordFromCurrentFormState,
} from "../Helpers/StateHelper";
import { callStatusEndpoint } from "../Helpers/ApiHelper";
import { CalendarService } from "./CalendarService";
import { CalendarDay } from "../Models/CalendarInfo";
import { TimesheetForm, validCodeInfo } from "../Models/TimesheetForm";
import { validateForm } from "../ModalForms/TimesheetModal/Middleware/ValidateForm";
import {
  findTimesheetTypeById,
  calculateTimesheetSumTotal,
} from "../Helpers/TimesheetTypeHelper";
import { Timesheet } from "../Models/Timesheet";
import { showConfirmPrompt } from "../Helpers/ConfirmPopup";
import { generateWarningsForTimesheetRecord } from "../Helpers/TimesheetEntryWarnings";
import { ApiStatusResult, TimesheetApi } from "./TimesheetApi";

export class TimesheetService {
  private modalEl: JQuery<HTMLElement>;

  constructor(
    private store: Store<RootState>,
    private api: TimesheetApi,
    private calendarService: CalendarService
  ) {
    this.modalEl = $("#CommonPopup");
    this.modalEl.on("hidden.bs.modal", () => this.resetForm());
  }

  showModal() {
    this.modalEl.modal("show");
  }

  closeModal() {
    this.modalEl.modal("hide");
  }

  private resetForm() {
    console.log("Reset timesheet form");

    this.store.setState((state) => ({
      ...state,
      Forms: {
        EditProfile: state.Forms.EditProfile,
        Timesheet: undefined,
      },
    }));
  }

  cancelEditingTimesheet() {
    this.closeModal();
  }

  async finishEditingTimesheet() {
    // Tell TimesheetModal component to validate all form fields
    this.store.setState((state) =>
      validateForm(
        setFormState<TimesheetForm>("Timesheet", state, {
          ValidateAll: true,
        })
      )
    );

    const state = this.store.getStateOrThrow();
    const formState = state.Forms.Timesheet;

    if (!formState) {
      toastr.error("Failed to retrieve form state.");
      return;
    }

    if (formState.Validation.InvalidItems.length > 0) {
      toastr.error("Please fix any validation errors before saving.");
      return;
    }

    let timesheetEntry: Timesheet | Timesheet[];

    try {
      timesheetEntry = createTimesheetRecordFromCurrentFormState(state);
    } catch (e) {
      toastr.error("Failed to parse timesheet entry: " + (e as Error).message);
      return;
    }

    if ("length" in timesheetEntry && timesheetEntry.length == 0) {
      toastr.warning("No timesheet entries to submit");
      return;
    }

    const employeeInfo = state.CalendarInfo.EmployeeInfo;
    const timesheetType = findTimesheetTypeById(
      state.TimesheetTypes,
      formState.TimesheetTypeId ?? -1
    );

    if (!timesheetType) {
      toastr.error(
        `Error: Invalid timesheet type '${formState.TimesheetTypeId}'`
      );
      return;
    }

    // Check for any validation warnings
    if (
      timesheetType &&
      employeeInfo &&
      validCodeInfo(formState.SelectedCodeInfo)
    ) {
      const warnings: string[] = generateWarningsForTimesheetRecord(
        employeeInfo.BusinessUnitCode,
        formState.SelectedCodeInfo,
        formState.CostCode,
        timesheetType
      );

      if (warnings.length) {
        for (const warning of warnings) {
          if (
            !(await showConfirmPrompt(warning + " Do you want to continue?"))
          ) {
            return;
          }
        }
      }
    }

    let result: ApiStatusResult;

    this.store.setState((state) =>
      setFormState<TimesheetForm>("Timesheet", state, {
        IsSaving: true,
      })
    );

    try {
      if ("length" in timesheetEntry) {
        result = await this.api.saveTimesheetRecordForWeek(timesheetEntry);
      } else {
        result = await this.api.saveTimesheetRecordForDay(timesheetEntry);
      }
    } catch (e) {
      toastr.error("Failed to save timesheet: " + (e as Error).message);
      return;
    } finally {
      this.store.setState((state) =>
        setFormState<TimesheetForm>("Timesheet", state, {
          IsSaving: false,
        })
      );
    }

    if (result.Successful) {
      toastr.success("Record saved successfully.");
      this.closeModal();
      this.calendarService.refresh();
    } else {
      const errorMessage = result.Error?.Description
        ? result.Error?.Description
        : result.Status;

      toastr.error(errorMessage, "Server Error", { timeOut: 20000 });
    }
  }

  addTimesheetEntryFromTemplate(
    timesheetTemplateId: number,
    selectedDate: Date
  ) {
    const state = this.store.getStateOrThrow();
    const timesheetTemplate = state.CalendarInfo.Favourites.find(
      (t) => t.TemplateId === timesheetTemplateId
    );

    if (!timesheetTemplate) {
      toastr.error("Failed to find timesheet template record");
      return;
    }

    const formState = initialTimesheetFormState(
      selectedDate,
      state.CalendarInfo.CurrentPeriodDays
    );

    formState.Title = timesheetTemplate.Title;
    formState.Code = timesheetTemplate.Code;
    formState.CostCode = timesheetTemplate.CostCode;
    formState.Description = timesheetTemplate.Description;
    formState.TimesheetTypeId = timesheetTemplate.TimesheetTypeId;
    formState.EmployeeCode = state.CalendarInfo.EmployeeCode;

    this.store.setState((state) => ({
      ...state,
      Forms: {
        ...state.Forms,
        Timesheet: formState,
      },
    }));

    this.showModal();
  }

  addTimesheetEntryToDay(day: CalendarDay) {
    const state = this.store.getStateOrThrow();
    const selectedDate = state.CalendarInfo.CurrentPeriodDays[day];
    const formState = initialTimesheetFormState(
      selectedDate,
      state.CalendarInfo.CurrentPeriodDays
    );

    formState.EmployeeCode = state.CalendarInfo.EmployeeCode;

    if (!state.TimesheetTypes?.length) {
      toastr.error("Failed to retrieve timesheet types");
      return;
    }

    // Set timesheet type to first type available
    formState.TimesheetTypeId =
      state.TimesheetTypes?.[0].TimesheetTypeId ?? null;

    this.store.setState((state) => ({
      ...state,
      Forms: {
        ...state.Forms,
        Timesheet: formState,
      },
    }));

    this.showModal();
  }

  editTimesheetEntry(timesheetId: number) {
    const state = this.store.getStateOrThrow();
    const timesheet = state.CalendarInfo.Timesheets.find(
      (ts) => ts.RecordId === timesheetId
    );

    if (!timesheet) {
      toastr.error("Failed to find timesheet record");
      return;
    }

    if (!timesheet?.TimesheetTypeId) {
      toastr.error("Invalid timesheet type ID");
      return;
    }

    const timesheetFormState = timesheetFormStateFromTimesheetRecord(timesheet);
    const timesheetType = findTimesheetTypeById(
      state.TimesheetTypes,
      timesheet.TimesheetTypeId
    );

    timesheetFormState.EmployeeCode = state.CalendarInfo.EmployeeCode;

    if (!timesheetType) {
      toastr.error("Failed to find timesheet type record");
      return;
    }

    if (timesheetType.IsProtected)
      timesheetFormState.RequiredTotalHours =
        calculateTimesheetSumTotal(timesheet);

    this.store.setState((state) => ({
      ...state,
      Forms: {
        ...state.Forms,
        Timesheet: timesheetFormState,
      },
    }));

    this.showModal();
  }

  async deleteTimesheetEntry(timesheetId: number) {
    const state = this.store.getStateOrThrow();

    const timesheetEntry = state.CalendarInfo.Timesheets.find(
      (ts) => ts.RecordId === timesheetId
    );

    if (!timesheetEntry) {
      toastr.error("Failed to find corresponding timesheet entry.");
      return;
    }

    const success = await callStatusEndpoint(() =>
      this.api.deleteTimesheetRecord(timesheetId, { showLoader: true })
    );

    if (!success) return;

    toastr.success("Successfully removed timesheet entry.");

    this.store.setState((state) => removeTimesheetEntry(state, timesheetId));
  }

  async copyTimesheetToNextDay(timesheetId: number) {
    const state = this.store.getStateOrThrow();

    const timesheetEntry = state.CalendarInfo.Timesheets.find(
      (ts) => ts.RecordId === timesheetId
    );

    if (!timesheetEntry) {
      toastr.error("Failed to find corresponding timesheet entry.");
      return;
    }

    const success = await callStatusEndpoint(() =>
      this.api.copyTimesheetToNextDay(timesheetId, { showLoader: true })
    );

    if (!success) return;

    toastr.success("Successfully copied timesheet entry.");

    await this.calendarService.refresh();
  }

  async copyTimesheetToNextWeek(timesheetId: number) {
    const state = this.store.getStateOrThrow();

    const timesheetEntry = state.CalendarInfo.Timesheets.find(
      (ts) => ts.RecordId === timesheetId
    );

    if (!timesheetEntry) {
      toastr.error("Failed to find corresponding timesheet entry.");
      return;
    }

    const success = await callStatusEndpoint(() =>
      this.api.copyTimesheetToWeek(timesheetId, { showLoader: true })
    );

    if (!success) return;

    toastr.success("Successfully copied timesheet entry.");

    await this.calendarService.refresh();
  }
}
