import { Timesheet, ShiftTypeCode } from "../Models/Timesheet";
import { TimesheetType } from "../Models/TimesheetType";
import { TimesheetTemplate } from "../Models/TimesheetTemplate";
import {
  getTimesheetIconInfo,
  IconInfo,
} from "../Helpers/GetTimesheetIconInfo";
import { parseCommonDate, getDaysOfWeekNames } from "../Helpers/DateHelper";
import { html } from "../Helpers/TemplateTags";
import { findTimesheetTemplateByTimesheetEntry } from "../Helpers/TimesheetTemplateHelper";
import {
  findTimesheetTypeById,
  calculateTimesheetSumTotal,
} from "../Helpers/TimesheetTypeHelper";
import { TimesheetPeriodStatusCode } from "../Models/CommonViewModel";
import { CalendarInfo } from "../Models/CalendarInfo";
import { mergeStatusCodes } from "../Helpers/TimesheetPeriodHelper";

type TimesheetCardActions = {
  canEdit: boolean;
  canDelete: boolean;
  canCopyToNextDay: boolean;
  canCopyToNextWeek: boolean;
};

export function render(
  timesheet: Timesheet,
  timesheetTypes: TimesheetType[],
  calendarInfo: CalendarInfo
) {
  const timesheetType = findTimesheetTypeById(
    timesheetTypes,
    timesheet.TimesheetTypeId
  );
  const timesheetTemplate = findTimesheetTemplateByTimesheetEntry(
    calendarInfo.Favourites,
    timesheet
  );

  if (!timesheetType) {
    toastr.error("Unknown timesheet entry type " + timesheet.RecordId);
    return;
  }

  if (!timesheet.WorkedDate) {
    toastr.error("Invalid timesheet entry date " + timesheet.RecordId);
    return;
  }

  const statusCode = mergeStatusCodes(
    timesheet.ItemStatusCode ?? "E",
    calendarInfo.TimesheetPeriodStatusCode
  );

  renderTimesheetItem(timesheet, statusCode, timesheetType, timesheetTemplate);
}

function renderTimesheetItem(
  timesheet: Timesheet,
  statusCode: TimesheetPeriodStatusCode,
  timesheetType: TimesheetType,
  timesheetTemplate?: TimesheetTemplate
) {
  let output = "";

  const jobCostCode =
    (timesheet.Code ?? "") +
    (timesheet.CostCode ? " / " + timesheet.CostCode : "");
  const title = timesheet.Title ?? "";
  const description = timesheet.Description ?? "";
  const isFavourite = !!timesheetTemplate;
  const canMaintain = timesheetType.CanMaintain && statusCode === "E";
  const canAddNew = timesheetType.CanAddNew;
  const isLeave = timesheetType.IsLeave;
  const isProtected = !!timesheetType.IsProtected;
  const hasAllowance = !!timesheetType.AllowanceCode;
  const hasBeenProcessed = statusCode === "P";
  const totalHours = calculateTimesheetSumTotal(timesheet);

  //////////////////////
  // Determine icon
  const iconInfo = getTimesheetIconInfo(timesheetType);

  //////////////////////
  // Determine footer button visibility
  const cardActions: TimesheetCardActions = {
    canEdit: true,
    canDelete: true,
    canCopyToNextDay: true,
    canCopyToNextWeek: true,
  };

  if (hasBeenProcessed || !canMaintain) {
    cardActions.canEdit = false;
    cardActions.canDelete = false;
    cardActions.canCopyToNextDay = false;
    cardActions.canCopyToNextWeek = false;
  }

  if (isProtected) {
    // Protected items can't be deleted / cloned
    cardActions.canDelete = false;
    cardActions.canCopyToNextDay = false;
    cardActions.canCopyToNextWeek = false;
  }

  if (!canAddNew) {
    cardActions.canCopyToNextDay = false;
    cardActions.canCopyToNextWeek = false;
  }

  //////////////////////
  // Allowance card
  if (
    hasAllowance ||
    timesheetType.NormalRateAccess == "S" ||
    timesheetType.NormalRateAccess == "R"
  ) {
    const allowanceForCard = (timesheet.NormalRate ?? 1) * timesheet.NormalQty;

    const displayQuantity =
      (timesheetType.NormalQtyAccess == "S" ||
        timesheetType.NormalQtyAccess == "R") &&
      (timesheetType.NormalRateAccess == "S" ||
        timesheetType.NormalRateAccess == "R");

    output += widgetHeader(
      timesheet.RecordId,
      hasBeenProcessed,
      jobCostCode,
      isFavourite,
      timesheet.ShiftType,
      iconInfo
    );

    output += html` <div class="widget-body">
      <span class="timesheet-card--title meeting-heading">
        ${title}
        <span class="pull-right">$${allowanceForCard.toFixed(2)}</span>
      </span>
      <p class="timesheet-card--description">${description}</p>
      <div class="timesheet-card--hours">
        ${displayQuantity ? "Quantity: " + timesheet.NormalQty : ""}
      </div>
      ${widgetValidationMessages(timesheet._validationWarnings)}
    </div>`;

    output += widgetFooter(timesheet.RecordId, statusCode, cardActions, description);

    //////////////////////
    // Leave card
  } else if (isLeave) {
    output += widgetHeader(
      timesheet.RecordId,
      hasBeenProcessed,
      title,
      isFavourite,
      timesheet.ShiftType,
      iconInfo
    );

    output += html` <div class="widget-body">
      <span  tabindex="0" class="timesheet-card--title meeting-heading">${title}</span>
      <p class="timesheet-card--description" title="${description}">
        ${description}
      </p>
      <div class="timesheet-card--hours">
        <span>Normal</span>
        <span>
          <i
            class="fa fa-clock-o margin-right-5 margin-left-5 text-muted font-100"
          ></i>
          <strong>${timesheet.NormalQty}hr(s)</strong>
        </span>
      </div>
      ${widgetValidationMessages(timesheet._validationWarnings)}
    </div>`;

    output += widgetFooter(timesheet.RecordId, statusCode, cardActions, description);

    //////////////////////
    // Default card - i.e. non-allowance/non-leave
  } else {
    output += widgetHeader(
      timesheet.RecordId,
      hasBeenProcessed,
      jobCostCode,
      isFavourite,
      timesheet.ShiftType,
      iconInfo
    );

    output += '<div class="widget-body">';

    if (title) {
      output +=
        '<span tabindex="0" class="timesheet-card--title meeting-heading">' +
        title +
        "</span>";
    }

    if (description) {
      output +=
        '<p class="timesheet-card--description">' + description + "</p>";
    }

    if (totalHours > 0) {
      output += `<div class="timesheet-card--hours shift-type-${
        timesheet.ShiftType?.toLowerCase() ?? "none"
      }">`;
      output += cardHoursComponent(
        "Normal",
        timesheet.NormalQty,
        "palegreen",
        "8px"
      );
      output += cardHoursComponent(
        "O/Time",
        timesheet.ExtraQty ?? 0,
        "storm-cloud",
        "8px"
      );
      output += cardHoursComponent(
        "Double",
        timesheet.OvertimeQty ?? 0,
        "orange",
        "8px"
      );
      output += cardHoursComponent(
        "Public",
        timesheet.PublicHolidayQty ?? 0,
        "danger",
        "8px"
      );
      output += "</div>";
    }

    output += widgetValidationMessages(timesheet._validationWarnings);

    output += "</div>"; // close .widget-body

    output += widgetFooter(timesheet.RecordId, statusCode, cardActions, description);
  }

  /////////////////////////
  // Determine dayKey from day
  const dayInfoList = getDaysOfWeekNames();

  const dayKey =
    dayInfoList[
      parseCommonDate(timesheet.WorkedDate ?? dayInfoList[0]).getDay()
    ];

  /////////////////////////
  // Append card HTML to DOM
  const $outerDiv = $(
    '<div class="widget" data-calendarCard data-recordId="' +
      timesheet.RecordId +
      '"></div>'
  );

  $outerDiv.html(output);

  if (!hasAllowance) $("#dvContainer" + dayKey + " div:eq(0)").after($outerDiv);
  else $("#dvContainer" + dayKey).append($outerDiv);
}

/**
 * Used to display the header section of the card.
 */
function widgetHeader(
  timesheetRecordId: number,
  hasBeenProcessed: boolean,
  title: string,
  isFavourite: boolean,
  shiftType: ShiftTypeCode | undefined,
  iconInfo: IconInfo
) {
  const favClass = isFavourite ? "fa-star" : "fa-star-o";
  const favColour = isFavourite ? "rgb(174, 83, 0)" : "rgb(119, 119, 119)";
  const favTooltip = isFavourite ? "Starred" : "Not Starred";

  const shiftTypeLabels = {
    A: "Afternoon Shift",
    N: "Night shift",
  };

  const shiftBadge = !shiftType
    ? ""
    : `<span class="shift-type-badge shift-type-${shiftType.toLowerCase()}">${
        shiftTypeLabels[shiftType]
      }</span>`;

  return html` <div
    class="widget-header ${hasBeenProcessed ? "is-processed" : ""}"
  >
    <span class="work-icon">
      <span
        class="${iconInfo.backgroundClass}"
        data-toggle="tooltip"
        data-placement="top"
        data-original-title="${iconInfo.label}"
        class="bg-storm-cloud"
        title="${iconInfo.label}"
      >
        <img src="${iconInfo.imagePath}" />
      </span>
    </span>
    <strong class="widget-header--title">${title}</strong>
    <span class="widget-header--right pull-right">
      ${shiftBadge}
      <button aria-label="Toggle Favourite ${title}" class="focus-btn icon-btn" data-toggleFavourite="${timesheetRecordId}">
        <i
          id="${timesheetRecordId}"
          class="fa ${favClass}"
          title="${favTooltip}"
          style="cursor: pointer; color: ${favColour};"
        ></i>
      </button>
    </span>
  </div>`;
}

/**
 * Used to display the footer section of the card which
 * contains various action buttons.
 */
function widgetFooter(
  timesheetRecordId: number,
  timesheetStatusCode: TimesheetPeriodStatusCode | null,
  actions: TimesheetCardActions,
  description: string
) {
  const disabledButtonStyle =
    "pointer-events: none; cursor: default; opacity:0.3;";

  let statusIconClass = "fa silver";
  let statusLabel = "";

  // Processed
  if (timesheetStatusCode === "P") {
    statusIconClass += " fa-check";
    statusLabel = "Processed";
    // Closed
  } else if (timesheetStatusCode === "C") {
    statusIconClass += " fa-lock";
    statusLabel = "Closed";
    // Open/Entering
  } else {
    statusIconClass = "";
    statusLabel = "Open";
  }

  return html` <div class="widget-footer">
    <div class="row clearfix">
      <ul class="footer-icon">
        <li>
          <div class="process-icon" style="margin:auto;" title="${statusLabel}">
            <i class="${statusIconClass}" style="cursor: default"></i>
          </div>
        </li>
        <li>
          <button aria-label="Edit timesheet entry ${description}" class="focus-btn icon-btn"  data-editTimesheet="${timesheetRecordId}" style="${actions.canEdit ? "" : disabledButtonStyle}">
            <i
              class="fa fa-pencil-square-o text-muted"
              title="Edit"
            ></i>
          </button>           
        </li>
        <li>
          <button aria-label="Delete timesheet entry ${description}" class="focus-btn icon-btn"  data-deleteTimesheet="${timesheetRecordId}"  style="${actions.canDelete ? "" : disabledButtonStyle}">
            <i
              class="fa fa-trash-o danger"
              title="Delete"
            ></i>
          </button>
        </li>
        <li>
          <button aria-label="Copy timesheet entry ${description} to the next day" class="focus-btn icon-btn" data-copyToNextDay="${timesheetRecordId}" style="${actions.canCopyToNextDay ? "" : disabledButtonStyle}">
            <i
              id="btn_copy"
              class="fa fa-files-o text-muted"
              title="Copy To Next Day"
            ></i>
          </button>
        </li>
        <li>
          <button aria-label="Copy timesheet entry ${description} to the end of the week" class="focus-btn icon-btn" data-copyToNextWeek="${timesheetRecordId}" style="${actions.canCopyToNextWeek ? "" : disabledButtonStyle}">
            <i
              id="btn_copy"
              class="fa fa-share-square-o text-muted"
              title="Copy To End Of The Week"
            ></i>
          </button>
        </li>
      </ul>
    </div>
  </div>`;
}

function widgetValidationMessages(messages?: string[]) {
  if (!messages || !messages.length) return "";

  return `
    <ul class="timesheet-card--validation-messages">
      ${messages
        .map((message) => `<li><strong>Warning:</strong> ${message}</li>`)
        .join("")}
    </ul>`;
}

/**
 * Used to display hours breakdown on cards
 * @param title Hour type title e.g. Overtime
 * @param hours Hours to display
 * @param badgeClass Badge CSS class
 * @param iconMarginLeft Additional margin style
 */
function cardHoursComponent(
  title: string,
  hours: number,
  badgeClass: string,
  iconMarginLeft: string
) {
  if (hours <= 0) return "";

  return html` <h6 class="line-height-0">
    <span class="hours-type badge-${badgeClass}"></span>
    ${title}
    <span>
      <i
        class="fa fa-clock-o margin-right-5 text-muted font-100"
        style="margin-left: ${iconMarginLeft};"
      ></i>
      <strong tabindex="0" >${hours}hr(s)</strong>
    </span>
    <h6></h6>
  </h6>`;
}
