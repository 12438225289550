import { Store } from "../../Helpers/Store";
import { RootState } from "../../Models/RootState";
import {
  TimesheetForm,
  TimesheetFormDayData,
  TimesheetFormWeekData,
} from "../../Models/TimesheetForm";
import { CalendarDay } from "../../Models/CalendarInfo";
import { lowerCaseFirstChar } from "../../Helpers/StringHelpers";
import { numberVal, stringVal } from "../../Helpers/JQueryHelper";
import { setFormState } from "../../Helpers/StateHelper";
import { setup as setupJobCodeAutocomplete } from "../../Helpers/JobCodeAutocomplete";
import { render } from "./Components/Form";
import { createMiddleware as createFormValidationMiddleware } from "./Middleware/ValidateForm";
import { createMiddleware as createSelectedCodeChangeMiddleware } from "./Middleware/CodeInfo";
import { createMiddleware as createResetWhenTypeChangesMiddleware } from "./Middleware/ResetWhenTypeChanges";
import { findTimesheetTypeById } from "../../Helpers/TimesheetTypeHelper";
import { TimesheetType } from "../../Models/TimesheetType";
import { ShiftTypeCode } from "../../Models/Timesheet";
import { TimesheetApi } from "../../Services/TimesheetApi";

const $form = $("#Add_Timesheet_Form");
const $periodCheckbox = $("#chkSlider");
const $timesheetTypeSelect = $("#TimesheetType");
const $jobCodeInput = $("#TimesheetJobCode");
const $titleInput = $("#TimesheetJobTitle");
const $costCodeSelect = $("#CostCode");
const $descriptionTextArea = $("#txtDescription");
const $shiftTypeSelect = $("#selShiftType");
const $entryOptionsContainer = $form.find("[data-timesheetEntryOptions]");

export const initialise = (api: TimesheetApi, store: Store<RootState>) => {
  setup(api, store);

  createResetWhenTypeChangesMiddleware(store);
  createSelectedCodeChangeMiddleware(api, store);
  createFormValidationMiddleware(store);

  store.subscribe("@init", (ps, s) => render(ps, s, true));
  store.subscribe("change", (ps, s) => {
    render(ps, s, false);
  });
};

/**
 * Setup component. This is only run once when component is initialised.
 * @param api TimesheetApi
 * @param store Store
 */
const setup = (api: TimesheetApi, store: Store<RootState>) => {
  // Make draggable
  $form.parent(".modal-content").draggable({ handle: ".modal-header" });

  // Notify store when fields change
  const inputs =
    "#Add_Timesheet_Form input, #Add_Timesheet_Form textarea, #Add_Timesheet_Form select";
  $(document).on("change", inputs, (e) => notifyStoreOfChange(e.target, store));

  // Period toggle
  $(document).on("change", "#chkSlider", () => {
    store.setState((state) =>
      setFormState<TimesheetForm>("Timesheet", state, {
        EntryPeriod: $periodCheckbox.is(":checked") ? "week" : "day",
      })
    );
  });

  // Project/GL code autocomplete
  setupJobCodeAutocomplete(store, $jobCodeInput);
};

/**
 * When any input changes we notifiy the store of the change.
 * @param target Target Element
 * @param store Store
 */
const notifyStoreOfChange = (target: HTMLElement, store: Store<RootState>) => {
  const state = store.getStateOrThrow();
  const formState = state.Forms.Timesheet;
  const newFormState: Partial<TimesheetForm> = {};

  if (!formState) return;

  const timesheetType = findTimesheetTypeById(
    state.TimesheetTypes,
    formState.TimesheetTypeId ?? -1
  );

  newFormState.TimesheetTypeId = numberVal($timesheetTypeSelect);
  newFormState.Code = stringVal($jobCodeInput)?.toUpperCase();
  newFormState.CostCode = stringVal($costCodeSelect);
  newFormState.Description = stringVal($descriptionTextArea);
  newFormState.ShiftType = stringVal($shiftTypeSelect) as ShiftTypeCode;
  newFormState.Title = stringVal($titleInput);

  // Day data
  newFormState.DayData = {
    ...formState.DayData,
    NormalQty:
      numberVal($entryOptionsContainer.find('input[name="dayNormalQty"]')) ??
      timesheetType?.NormalQtyDefault ??
      null,
    ExtraQty:
      numberVal($entryOptionsContainer.find('input[name="dayExtraQty"]')) ??
      null,
    OvertimeQty:
      numberVal($entryOptionsContainer.find('input[name="dayOvertimeQty"]')) ??
      null,
    PublicHolidayQty:
      numberVal(
        $entryOptionsContainer.find('input[name="dayPublicHolidayQty"]')
      ) ?? null,
    NormalRate:
      numberVal($entryOptionsContainer.find('input[name="dayNormalRate"]')) ??
      timesheetType?.NormalRateDefault ??
      null,
  };

  // Week data
  if (formState.WeekData) {
    newFormState.WeekData = {
      Monday: getDataForWeekDay(formState.WeekData, "Monday", timesheetType),
      Tuesday: getDataForWeekDay(formState.WeekData, "Tuesday", timesheetType),
      Wednesday: getDataForWeekDay(
        formState.WeekData,
        "Wednesday",
        timesheetType
      ),
      Thursday: getDataForWeekDay(
        formState.WeekData,
        "Thursday",
        timesheetType
      ),
      Friday: getDataForWeekDay(formState.WeekData, "Friday", timesheetType),
      Saturday: getDataForWeekDay(
        formState.WeekData,
        "Saturday",
        timesheetType
      ),
      Sunday: getDataForWeekDay(formState.WeekData, "Sunday", timesheetType),
    };
  }

  // If the target element has a name attribute
  // add it to the list of dirty (modified) fields
  const targetElNameAttr = $(target).attr("name");

  if (targetElNameAttr && !formState.DirtyFields.includes(targetElNameAttr))
    formState.DirtyFields = [...formState.DirtyFields, targetElNameAttr];

  // Update store
  store.setState((state) =>
    setFormState<TimesheetForm>("Timesheet", state, newFormState)
  );
};

const getElForDay = (day: string, type: string) =>
  $(`input[name="${lowerCaseFirstChar(day)}${type}"]`);

const getDataForWeekDay = (
  weekData: TimesheetFormWeekData,
  day: CalendarDay,
  timesheetType?: TimesheetType
): TimesheetFormDayData => ({
  WorkedDate: weekData[day].WorkedDate,
  NormalQty:
    numberVal(getElForDay(day, "NormalQty")) ??
    timesheetType?.NormalQtyDefault ??
    null,
  ExtraQty: numberVal(getElForDay(day, "ExtraQty")) ?? null,
  OvertimeQty: numberVal(getElForDay(day, "OvertimeQty")) ?? null,
  PublicHolidayQty: numberVal(getElForDay(day, "PublicHolidayQty")) ?? null,
  NormalRate:
    numberVal(getElForDay(day, "NormalRate")) ??
    timesheetType?.NormalRateDefault ??
    null,
});
