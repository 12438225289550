import { CalendarDay } from "./CalendarInfo";
import { TimesheetStatusCode, ShiftTypeCode } from "./Timesheet";
import { BaseForm } from "./BaseForm";
import { WorkCode } from "./WorkCode";
import { APIError } from "./APIError";

export type TimesheetFormDayData = {
  WorkedDate: Date;
  NormalQty: number | null;
  ExtraQty: number | null;
  OvertimeQty: number | null;
  PublicHolidayQty: number | null;
  NormalRate?: number | null;
};

export type TimesheetFormWeekData = {
  [key in CalendarDay]: TimesheetFormDayData;
};

export type CodeInfo = WorkCode | APIError;

export type TimesheetEntryPeriod = "day" | "week";

export type TimesheetForm = BaseForm & {
  EntryPeriod: TimesheetEntryPeriod;
  RecordId: number;
  EmployeeCode: string | null;
  TimesheetTypeId: number | null;
  Code: string | null;
  CostCode: string | null;
  Description: string | null;
  ShiftType: ShiftTypeCode;
  Title: string | null;
  ItemStatusCode: TimesheetStatusCode | null;
  TimesheetStatusCode: TimesheetStatusCode | null;
  WeekData?: TimesheetFormWeekData;
  DayData: TimesheetFormDayData;
  SelectedCodeInfo?: CodeInfo;
  IsSelectedCodeInfoLoading?: boolean;
  ValidateAll?: boolean;
  DirtyFields: string[];
  RequiredTotalHours?: number;
  IsSaving?: boolean;
};

export const validCodeInfo = (
  codeInfo: CodeInfo | undefined
): codeInfo is WorkCode => {
  return (
    !!codeInfo?.Type && typeof (codeInfo as APIError)?.Location == "undefined"
  );
};
