import isEqual from "lodash.isequal";
import { RootState } from "../../../Models/RootState";
import { findTimesheetTypeById } from "../../../Helpers/TimesheetTypeHelper";
import { renderTimesheetTypeOptionsForDay } from "./OptionsForDay";
import { renderTimesheetTypeOptionsForWeek } from "./OptionsForWeek";
import { findFavouriteForTimesheetEntry } from "../../../Helpers/StateHelper";
import { validCodeInfo } from "../../../Models/TimesheetForm";
import { TimesheetType } from "../../../Models/TimesheetType";
import { CostCode } from "../../../Models/CostCode";

const $form = $("#Add_Timesheet_Form");
const $futureWarningMessage = $("#lblFutureTSEntry");
const $periodCheckboxDiv = $("#divChkSilder");
const $periodCheckbox = $("#chkSlider");
const $timesheetTypeSelect = $("#TimesheetType");
const $titleInput = $("#TimesheetJobTitle");
const $descriptionTextArea = $("#txtDescription");
const $shiftTypeDiv = $(".form-group--shift-type");
const $shiftTypeSelect = $("#selShiftType");
const $costCodeDiv = $("#divCostCode");
const $costCodeSelect = $("#CostCode");
const $jobTitleDiv = $("#divJobTitle");
const $jobCodeDiv = $("#divJobCode");
const $jobCodeInput = $("#TimesheetJobCode");
const $saveButton = $form.find("[data-saveEditedTimesheet]");
const $entryOptionsContainer = $form.find("[data-timesheetEntryOptions]");
const $favouriteButton = $form.find("[data-addFavouriteFromOpenTimesheet]");

/**
 * Handles rendering changes to the Timesheet form
 * @param prevState Previous State
 * @param state Current State
 * @param firstRender Is first render?
 */
export const render = (
  prevState: RootState | undefined,
  state: RootState,
  firstRender: boolean
) => {
  const formState = state.Forms.Timesheet;

  // Timesheet types dropdown
  if (
    !isEqual(prevState?.TimesheetTypes, state.TimesheetTypes) ||
    prevState?.Forms.Timesheet?.TimesheetTypeId !== formState?.TimesheetTypeId
  ) {
    renderTimesheetTypeDropdown(
      $timesheetTypeSelect,
      state.TimesheetTypes,
      formState?.TimesheetTypeId
    );
  }

  // Everything after here requires form state to be defined
  if (!formState) {
    return;
  }

  const timesheetType = formState.TimesheetTypeId
    ? findTimesheetTypeById(state.TimesheetTypes, formState.TimesheetTypeId)
    : undefined;

  // Don't bother re-rendering if state hasn't changed
  if (
    !firstRender &&
    isEqual(prevState?.Forms.Timesheet, formState) &&
    isEqual(prevState?.CalendarInfo.Favourites, state.CalendarInfo.Favourites)
  ) {
    return;
  }

  // Jobs code input
  if (prevState?.Forms.Timesheet?.Code !== formState.Code) {
    $jobCodeInput.val(formState.Code ?? "");
  }

  $jobCodeInput.prop("readonly", !!timesheetType?.IsProtected);

  // Hide job code input for leave items
  $jobCodeDiv.toggle(!timesheetType?.IsLeave);

  // Render cost codes dropdown
  if (
    validCodeInfo(formState.SelectedCodeInfo) &&
    !isEqual(
      prevState?.Forms.Timesheet?.SelectedCodeInfo,
      formState.SelectedCodeInfo.CostCodes
    )
  ) {
    renderCostCodesDropdown(
      $costCodeSelect,
      formState.SelectedCodeInfo.CostCodes
    );
  }

  // Set cost code
  $costCodeSelect.val(formState.CostCode ?? "");
  $costCodeSelect.prop("disabled", !!timesheetType?.IsProtected);

  // Timesheet type changed
  if (prevState?.Forms.Timesheet?.TimesheetTypeId !== formState.TimesheetTypeId)
    $timesheetTypeSelect.val(formState.TimesheetTypeId ?? "");

  // Show/hide future date message
  $futureWarningMessage.toggle(
    formState.DayData.WorkedDate.getTime() > Date.now()
  );

  // Show day/week checkbox for new entries
  $periodCheckboxDiv.toggle(formState.RecordId === 0);

  // Set period checkbox state
  if (prevState?.Forms.Timesheet?.EntryPeriod !== formState.EntryPeriod)
    $periodCheckbox.prop("checked", formState.EntryPeriod === "week");

  // Set description
  $descriptionTextArea.val(formState.Description ?? "");
  $descriptionTextArea.prop("readonly", !!timesheetType?.IsProtected);

  // Set shift type
  $shiftTypeSelect.val(formState.ShiftType ?? "");
  $shiftTypeSelect.prop("readonly", timesheetType?.ShiftTypeAccess === "R");

  // Show/hide shift type
  $shiftTypeDiv.toggle(timesheetType?.ShiftTypeAccess !== "H");

  // If timesheet type or period changes, re-render Day/Week options
  if (
    prevState?.Forms.Timesheet?.EntryPeriod !== formState.EntryPeriod ||
    prevState?.Forms.Timesheet?.TimesheetTypeId !== formState.TimesheetTypeId ||
    prevState?.Forms.Timesheet?.DayData !== formState.DayData ||
    prevState?.Forms.Timesheet?.WeekData !== formState?.WeekData
  ) {
    const $emptyContainer = $("<div></div>");

    let wageType = state.CalendarInfo.WageType;

    if (timesheetType && wageType) {
      if (formState.EntryPeriod === "day")
        renderTimesheetTypeOptionsForDay(
          $emptyContainer,
          wageType,
          timesheetType,
          formState
        );
      else
        renderTimesheetTypeOptionsForWeek(
          $emptyContainer,
          wageType,
          timesheetType,
          formState
        );
    }

    // Renders just what changed
    $entryOptionsContainer.vhtml($emptyContainer.html());
  }

  // Find matching favourite template
  const matchingFavourite = findFavouriteForTimesheetEntry(state, formState);

  // Enable/disable favourite button
  const favouriteButtonEnabled =
    !!formState.TimesheetTypeId && !!formState.Code;
  $favouriteButton.toggleClass("is-disabled", !favouriteButtonEnabled);

  // Toggle favourite found icon
  $favouriteButton.toggleClass("has-favourite", !!matchingFavourite);

  // Toggle loading spinner for cost code info is loading
  $jobCodeInput
    .parent()
    .toggleClass("is-loading", !!formState.IsSelectedCodeInfoLoading);

  // Show/hide title field
  const costCodeTitle =
    validCodeInfo(formState.SelectedCodeInfo) &&
    formState.SelectedCodeInfo.Title
      ? formState.SelectedCodeInfo.Title
      : "";
  $jobTitleDiv.toggle(!!costCodeTitle);
  $titleInput.val(costCodeTitle);

  // Show/hide cost code dropdown
  $costCodeDiv.toggle(
    validCodeInfo(formState.SelectedCodeInfo) &&
      formState.SelectedCodeInfo?.CostCodes.length > 0
  );

  // Disable save button if not ready to save
  $saveButton.prop(
    "disabled",
    formState.IsSaving || // Form is being saved
      (!timesheetType?.IsLeave && !formState.Code) || // or missing required data
      formState.IsSelectedCodeInfoLoading || // or still loading CostCode options
      formState.Validation.InvalidItems.length > 0
  ); // or have validation errors

  if (formState.IsSaving && !$saveButton.find("fa").length) {
    $saveButton.append($('<i class="fa-spinner fa-spin fa"></i>'));
  } else {
    $saveButton.find(".fa-spinner").remove();
  }

  // Reset validation errors
  $form.find(".form-group.has-error").removeClass("has-error");
  $form.find("[data-validationError]").remove();

  // Show any validation errors
  formState.Validation.InvalidItems.forEach((validationError) => {
    let $el = $(validationError.Selector);

    const $label = $el.next("label");
    $el = $label.length ? $label : $el;

    const $parent = $el.closest(".input-group");
    $el = $parent.length ? $parent : $el;

    $el.next("[data-validationError]").remove();
    $el.after(
      `<small class="help-block" data-validationError>${validationError.Message}</small>`
    );
    $el.closest(".form-group").addClass("has-error");
  });
};

const renderCostCodesDropdown = (
  $select: JQuery<HTMLElement>,
  costCodes: CostCode[]
) => {
  costCodes.forEach((costCode) => {
    const code = costCode.Code;

    // If option is already available then skip it
    if ($select.find(`option[value="${code}"]`).length) return;

    const $option = $(`<option value="${code}">${costCode.Code}</option>`);

    $select.append($option);
  });

  // Remove stale options
  $select.find("option").map((_, el) => {
    const code = $(el).val()?.toString();

    if (code && !costCodes.some((cc) => cc.Code === code)) {
      $(el).remove();
    }
  });

  $select.prop("disabled", costCodes.length < 1);
};

const renderTimesheetTypeDropdown = (
  $select: JQuery<HTMLElement>,
  timesheetTypes: TimesheetType[],
  currentTimesheetTypeId?: number | null
) => {
  let disableSelect = false;

  timesheetTypes.forEach((timesheetType) => {
    const value = timesheetType.TimesheetTypeId;

    // Only certain types can be selected
    if (!timesheetType.CanAddNew && currentTimesheetTypeId !== value) {
      $select.find(`option[value="${value}"]`).remove();
      return;
    }

    // If current timesheet type cannot be selected
    // make the dropdown read only
    if (value === currentTimesheetTypeId && !timesheetType.CanAddNew)
      disableSelect = true;

    // If option is already available then skip it
    if ($select.find(`option[value="${value}"]`).length) return;

    const $option = $(
      `<option value="${value}">${timesheetType.Description}</option>`
    );

    $select.append($option);
  });

  $select.prop("disabled", disableSelect);
};
