import $ from "jquery";
import "bootstrap";
import toastr from "toastr";
import isEqual from "lodash.isequal";

import {
  CommonViewModel,
  TimesheetPeriodStatusCode,
} from "./Models/CommonViewModel";
import { SessionInfo } from "./Models/SessionInfo";
import { Store } from "./Helpers/Store";
import { RootState } from "./Models/RootState";
import { TimesheetService } from "./Services/TimesheetService";
import { FavouriteService } from "./Services/FavouriteService";
import { ServiceProvider } from "./Models/ServiceProvider";
import { CalendarDay } from "./Models/CalendarInfo";
import { ImpersonationInfo } from "./Models/ImpersonationInfo";
import { CalendarService } from "./Services/CalendarService";
import { addCommonViewModelToState } from "./Helpers/StateHelper";
import { ProfileService } from "./Services/ProfileService";
import {
  hideLoader,
  showFatalError,
  showLoginScreen,
} from "./Helpers/ApiHelper";
import { diff } from "./Helpers/DiffObject";
import { setup as setupHistoryHelper } from "./Helpers/HistoryHelper";

import { render as renderTopHeader } from "./Components/TopHeader";
import { render as renderFavouriteList } from "./Components/FavouriteList";
import { render as renderTotalHoursByType } from "./Components/TotalHoursByType";
import { render as renderOverallTotal } from "./Components/OverallTotal";
import { render as renderCalendarView } from "./Components/CalendarView";
import { render as renderCalendarPager } from "./Components/CalendarPager";
import { render as renderCalendarDatepicker } from "./Components/CalendarDatepicker";
import { render as renderTimesheetSummary } from "./Components/TimesheetSummary";
import { render as renderEmployeeSearchHeader } from "./Components/EmployeeSearchHeader";
import { initialise as initialiseEditProfileModal } from "./ModalForms/EditProfileModal/index";
import { initialise as initialiseTimesheetModal } from "./ModalForms/TimesheetModal/index";
import { createMiddleware as initialiseTimesheetCardMiddleware } from "./Middleware/TimesheetMetadata";
import { preloadAppData } from "./Helpers/AppDataHelper";
import { AuthModule } from "./Helpers/AuthModule";
import { TimesheetApi } from "./Services/TimesheetApi";
import { TeamMemberEmployee } from "./Models/TeamMemberEmployee";
import appInsights from "./ApplicationInsights";

export async function load(authModule: AuthModule): Promise<void> {
  try {
    const api = new TimesheetApi(authModule);
    const data = await preloadAppData(api);

    init(
      api,
      data.commonViewModel,
      data.sessionInfo,
      data.sessionEmployeeInfo,
      data.impersonationInfo,
      data.isMobile
    );
  } catch (e) {
    if ((e as any).name == "invalid_session") {
      toastr.warning("Please login to continue.", "Invalid Session");
      showLoginScreen();
      hideLoader();
      return;
    }

    console.error("Failed to load app", e);
    toastr.error((e as any).message, "Failed to load app");
    hideLoader();
    showFatalError();
  }
}

function init(
  api: TimesheetApi,
  commonViewModel: CommonViewModel,
  sessionInfo: SessionInfo,
  sessionEmployeeInfo: TeamMemberEmployee,
  impersonationInfo?: ImpersonationInfo,
  isMobile: boolean = false
) {
  const store = new Store<RootState>();

  const calendarService = new CalendarService(store, api);

  // Setup simple service locator
  const services: ServiceProvider = {
    timesheetService: new TimesheetService(store, api, calendarService),
    favouriteService: new FavouriteService(store, api),
    profileService: new ProfileService(store, api),
    timesheetApi: api,
    calendarService,
  };

  console.log("IsMobile", isMobile);

  if (isMobile) {
    $("body").removeClass("is-desktop").addClass("is-mobile");
    $("#CommonPopup").before($("#main-container-mobile").html());
  } else {
    $("#CommonPopup").before($("#main-container-desktop").html());
  }

  // Subscribe to store initialisation
  store.subscribe("@init", (prevState, state) => {
    renderComponents(prevState, state, services, true);

    // Bind all global event handlers
    bindEventHandlers(services);

    hideLoader();
  });

  // Subscribe to store changes
  store.subscribe("change", (prevState, state) =>
    renderComponents(prevState, state, services, false)
  );

  // Initialise middleware
  initialiseTimesheetCardMiddleware(api, store);

  // Initialise forms
  initialiseEditProfileModal(store);
  initialiseTimesheetModal(api, store);

  // History handler
  setupHistoryHelper(calendarService, store);

  // Set initial state
  store.setInitialState(() =>
    addCommonViewModelToState(
      {
        CalendarInfo: {
          AccountName: impersonationInfo
            ? impersonationInfo.AccountName
            : sessionInfo.AccountName,
          EmployeeCode: impersonationInfo
            ? impersonationInfo.EmployeeCode
            : sessionInfo.EmployeeCode,
          EmployeeDisplayName: impersonationInfo
            ? impersonationInfo.EmployeeDisplayName
            : sessionInfo.EmployeeName,
        },
        EmployeeInfo: sessionEmployeeInfo,
        SessionInfo: sessionInfo,
        IsMobile: isMobile,
        Forms: {},
      } as RootState,
      commonViewModel
    )
  );
}

/**
 * Triggered when state changes, handles component render/re-render logic.
 * @param prevState Previous State
 * @param state Current State
 * @param services Services
 * @param firstRender Whether this is the initial render
 */
function renderComponents(
  prevState: RootState | undefined,
  state: RootState,
  services: ServiceProvider,
  firstRender: boolean
) {
  // Add store state debug logging
  // Note: This will be stripped out of production builds
  if (IS_DEV) {
    if (firstRender) {
      console.log("Finished initialising AppState", state);
    } else {
      console.log("App state changed");
      console.log("- State", state);
      console.log("- Diff", diff(prevState ?? state, state));
    }
  }

  // Top Header
  if (
    firstRender ||
    prevState?.SessionInfo?.EmployeeCode !== state.SessionInfo.EmployeeCode
  ) {
    renderTopHeader(state.SessionInfo);
  }

  // Check if calender info changed
  // It's generally not super efficient to perform a deep equality check
  // but state shouldn't change very often in this app
  if (!isEqual(prevState?.CalendarInfo, state.CalendarInfo)) {
    renderFavouriteList(
      state.CalendarInfo,
      state.TimesheetTypes,
      services.timesheetService,
      state.IsMobile
    );

    renderTotalHoursByType(
      state.CalendarInfo.WageType,
      state.TimesheetTypes,
      state.CalendarInfo.Timesheets,
      state.IsMobile
    );

    renderOverallTotal(
      state.TimesheetTypes,
      state.CalendarInfo.Timesheets,
      state.IsMobile,
      firstRender
    );

    renderCalendarView(
      state.SessionInfo,
      state.CalendarInfo,
      state.TimesheetTypes,
      state.IsMobile,
      firstRender
    );

    renderCalendarPager(state.CalendarInfo, state.IsMobile);

    renderTimesheetSummary(
      state.CalendarInfo.Timesheets,
      state.TimesheetTypes,
      state.IsMobile
    );
  }

  // Calendar dates changed
  if (
    prevState?.CalendarInfo?.SelectedDate != state.CalendarInfo.SelectedDate ||
    prevState?.CalendarInfo.StartDate != state.CalendarInfo.StartDate ||
    prevState?.CalendarInfo.EndDate != state.CalendarInfo.EndDate
  ) {
    renderCalendarDatepicker(state.CalendarInfo, services, firstRender);
  }

  // Employee search header
  if (
    firstRender ||
    prevState?.CalendarInfo?.EmployeeCode !== state.CalendarInfo.EmployeeCode
  ) {
    renderEmployeeSearchHeader(
      state.SessionInfo,
      state.CalendarInfo,
      services.timesheetApi,
      services.calendarService,
      firstRender
    );
  }
}

/**
 * Binds all global event handlers.
 * When adding a new handler make sure to unbind it first.
 * @param services Services
 */
function bindEventHandlers(services: ServiceProvider) {
  // Click handler helper
  const bindDataClickHandler = (attr: string, callback: () => void) => {
    $(document).off("click", `[${attr}]`);

    $(document).on("click", `[${attr}]`, function (e) {
      e.preventDefault();
      callback();
    });
  };

  // Click handler helper, used when attribute contains value
  const bindDataClickHandlerWithValue = (
    attr: string,
    callback: (value: string) => void
  ) => {
    $(document).off("click", `[${attr}]`);

    $(document).on("click", `[${attr}]`, function (e) {
      e.preventDefault();

      const value = $(this).attr(attr) ?? "";

      if (!value)
        return toastr.error(
          'Something went wrong. Failed to find "' + attr + '" value.'
        );

      callback(value);
    });
  };

  // Click handler helper, used when attribute contains numeric value
  const bindDataClickHandlerWithNumericValue = (
    attr: string,
    callback: (value: number) => void
  ) => {
    bindDataClickHandlerWithValue(attr, (value) => callback(+value));
  };

  // Bind listeners

  bindDataClickHandlerWithNumericValue("data-removeFavourite", (templateId) =>
    services.favouriteService.removeFromFavouriteList(templateId)
  );

  bindDataClickHandlerWithNumericValue(
    "data-toggleFavourite",
    (timesheetRecordId) =>
      services.favouriteService.toggleFavouriteByTimesheetRecordId(
        timesheetRecordId
      )
  );

  bindDataClickHandlerWithValue("data-addTimesheetToDay", (day) =>
    services.timesheetService.addTimesheetEntryToDay(day as CalendarDay)
  );

  bindDataClickHandlerWithNumericValue("data-editTimesheet", (timesheetId) =>
    services.timesheetService.editTimesheetEntry(timesheetId)
  );

  bindDataClickHandlerWithNumericValue("data-deleteTimesheet", (timesheetId) =>
    services.timesheetService.deleteTimesheetEntry(timesheetId)
  );

  bindDataClickHandlerWithNumericValue("data-copyToNextDay", (timesheetId) =>
    services.timesheetService.copyTimesheetToNextDay(timesheetId)
  );

  bindDataClickHandlerWithNumericValue("data-copyToNextWeek", (timesheetId) =>
    services.timesheetService.copyTimesheetToNextWeek(timesheetId)
  );

  bindDataClickHandler("data-moveCalendarToPrevWeek", () =>
    services.calendarService.moveToPrevWeek()
  );

  bindDataClickHandler("data-moveCalendarToNextWeek", () =>
    services.calendarService.moveToNextWeek()
  );

  bindDataClickHandler("data-editProfile", () =>
    services.profileService.startEditingProfile()
  );

  bindDataClickHandlerWithValue(
    "data-updateCurrentTimesheetStatus",
    (newStatus) =>
      services.calendarService.setCurrentPeriodStatus(
        newStatus as TimesheetPeriodStatusCode
      )
  );

  // Edit profile modal
  bindDataClickHandler("data-cancelEditingProfile", () =>
    services.profileService.cancelEditingProfile()
  );

  bindDataClickHandler("data-saveEditedProfile", () =>
    services.profileService.finishEditingProfile()
  );

  $(document).off("submit", "[data-editProfileForm]");
  $(document).on("submit", "[data-editProfileForm]", (e) => {
    e.preventDefault();
  });

  // Add/edit timesheet modal
  bindDataClickHandler("data-cancelEditingTimesheet", () =>
    services.timesheetService.cancelEditingTimesheet()
  );

  bindDataClickHandler("data-saveEditedTimesheet", () =>
    services.timesheetService.finishEditingTimesheet()
  );

  bindDataClickHandler("data-addFavouriteFromOpenTimesheet", () =>
    services.favouriteService.addFavouriteFromOpenTimesheet()
  );

  $(document).off("submit", "[data-addTimesheetForm]");
  $(document).on("submit", "[data-addTimesheetForm]", (e) => {
    e.preventDefault();
  });
}
