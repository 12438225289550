import { RootState } from "../Models/RootState";
import { Store } from "./Store";
import { setFormState } from "./StateHelper";
import { JobCodeAutocompleteItem } from "../Models/JobCodeAutocompleteResult";
import { AutocompleteOption } from "../Models/AutocompleteOption";

export const setup = (store: Store<RootState>, $el: JQuery<HTMLElement>) => {
  $el.autocomplete({
    appendTo: "#CommonPopup",
    source: async (
      request: { term: string },
      response: (val: AutocompleteOption[]) => void
    ) => {
      const state = store.getStateOrThrow();
      const timesheetTypeId = state.Forms.Timesheet?.TimesheetTypeId;

      // Reset CostCode property
      store.setState((state) =>
        setFormState("Timesheet", state, { CostCode: null })
      );

      if (!timesheetTypeId) {
        response([]);
        return;
      }

      // Call API
      const lcTerm = request.term.toLowerCase().trim();
      const result = state.CalendarInfo.Favourites.filter(
        (tt) =>
          tt.Code?.toLowerCase().includes(lcTerm) ||
          tt.CostCode?.toLowerCase().includes(lcTerm) ||
          tt.Description?.toLowerCase().includes(lcTerm) ||
          tt.TimesheetTypeId == timesheetTypeId
      );

      // Return list of results for dropdown
      response(
        result.map((item) => {
          let labelForDisplay = "";

          if (item.Code != null && item.CostCode != null) {
            if (item.Description != null)
              labelForDisplay =
                item.Code + "/" + item.CostCode + " - " + item.Description;
            else labelForDisplay = item.Code + "/" + item.CostCode;
          } else {
            if (item.Description != null)
              labelForDisplay = item.Code + " - " + item.Description;
            else labelForDisplay = item.Code ?? "";
          }

          return {
            label: labelForDisplay,
            val: JSON.stringify(item),
          };
        })
      );
    },
    select: (_, ui) => {
      if (ui.item.val == -1) {
        return false;
      }

      const item: JobCodeAutocompleteItem = JSON.parse(ui.item.val);

      $el.val(item.Code);

      store.setState((state) => {
        if (!state.Forms.Timesheet) return state;

        const template = state.CalendarInfo.Favourites.find(
          (f) => f.TemplateId == item.TemplateId
        );

        return {
          ...state,
          Forms: {
            ...state.Forms,
            Timesheet: {
              ...state.Forms.Timesheet,
              Code: item.Code,
              CostCode: item.CostCode,
              Description: template?.Description ?? null,
              Title: template?.Title ?? null,
            },
          },
        };
      });

      return false;
    },
  });
};
