import {
  calculateLoggedHours,
  calculateTotalAllowance,
} from "../Helpers/TimesheetTypeHelper";
import { TimesheetType } from "../Models/TimesheetType";
import { Timesheet } from "../Models/Timesheet";
import { formatTotal } from "../Helpers/StringHelpers";

export function render(
  timesheetTypes: TimesheetType[],
  timesheets: Timesheet[],
  isMobile: boolean,
  firstRender: boolean
) {
  const loggedHours = calculateLoggedHours(timesheetTypes, timesheets);
  const totalAllowance = calculateTotalAllowance(timesheetTypes, timesheets);

  $("#spLoggedHours").text(formatTotal(loggedHours) + " hrs").attr('aria-description', "Total weekly hours");
  $("#spTotalAllowance").text("$" + formatTotal(totalAllowance));

  // Hide and show summary icon based on the data
  if (loggedHours > 0 || totalAllowance > 0) {
    $(isMobile ? ".summary-icon-mobile" : ".summary-icon").show();
  } else {
    $(isMobile ? ".summary-icon-mobile" : ".summary-icon").hide();
    $(isMobile ? ".tsSummaryMobile" : ".tsSummary").hide();
  }

  if (firstRender) bindToggleButton();
}

function bindToggleButton() {
  $(".summary-icon, .summary-icon-mobile").click(() => {
    $(".tsSummary, .tsSummaryMobile").slideToggle("fast");
  });
}
