import { CalendarInfo } from "../Models/CalendarInfo";
import { getShortMonthNames } from "../Helpers/DateHelper";

export function render(calendarInfo: CalendarInfo, isMobile: boolean) {
  const startDate = calendarInfo.StartDate;
  const endDate = calendarInfo.EndDate;

  let startDateDisplay = "";
  let endDateDisplay = "";

  const sameYear = startDate.getFullYear() === endDate.getFullYear();
  const sameMonth = sameYear && startDate.getMonth() === endDate.getMonth();

  // Day
  startDateDisplay += startDate.getDate().toString() + " ";
  endDateDisplay += endDate.getDate().toString() + " ";

  // Month
  startDateDisplay += sameMonth
    ? ""
    : getShortMonthNames()[startDate.getMonth()] + " ";
  endDateDisplay += getShortMonthNames()[endDate.getMonth()] + " ";

  // Year
  startDateDisplay += sameYear
    ? ""
    : startDate
        .getFullYear()
        .toString()
        .slice(isMobile ? -2 : 0);
  endDateDisplay += endDate
    .getFullYear()
    .toString()
    .slice(isMobile ? -2 : 0);

  // Week start & end date
  $("#spWeekStartEndDate").text(
    startDateDisplay.trim() + " - " + endDateDisplay.trim()
  ).attr('aria-description', "Current Week View");
}
