import { setup as setupAutocomplete } from "../Helpers/SearchBoxAutocomplete";
import { CalendarService } from "../Services/CalendarService";
import { SessionInfo, EMPLOYEE_ROLE_PAYROLL } from "../Models/SessionInfo";
import { CalendarInfo } from "../Models/CalendarInfo";
import { TimesheetApi } from "../Services/TimesheetApi";

export function render(
  sessionInfo: SessionInfo,
  calendarInfo: CalendarInfo,
  api: TimesheetApi,
  calendarService: CalendarService,
  firstRender: boolean
) {
  const canSeeSearch = sessionInfo.EmployeeRole === EMPLOYEE_ROLE_PAYROLL;

  if (firstRender && canSeeSearch) {
    setup(api, calendarService);
  }

  let title = "";

  if (sessionInfo.EmployeeCode !== calendarInfo.EmployeeCode) {
    title =
      "timesheet for " +
      calendarInfo.EmployeeDisplayName +
      " / " +
      calendarInfo.EmployeeCode;
  }

  $("#lblEmployeeName").text(title);

  $("#dvHeader").toggle(canSeeSearch);
}

const setup = (api: TimesheetApi, calendarService: CalendarService) => {
  setupAutocomplete(api, calendarService, $("#emp_search_box"));
};
