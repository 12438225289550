declare type Handlers<T> = {
  [key: string]: Handler<T>[];
};

declare type Middleware<T> = (
  prevState: T | undefined,
  state: T,
  next: (state: T) => void
) => void;

declare type Handler<T> = (prevState: T | undefined, state: T) => void;

declare type EventType = "@init" | "change";

/**
 * Simple data store with support for pub/sub events
 * when the store state changes.
 *
 * @remarks Originally the plan was to use a library like redux
 * for state management but because this is gradually being
 * rewritten from the original outsourced version which had state
 * management spaghetti, a simpler solution was needed to aid in
 * a partial migration.
 */
export class Store<TState> {
  private state: TState | undefined;
  private handlers: Handlers<TState>;
  private middlewares: Middleware<TState>[];

  constructor() {
    this.handlers = {};
    this.middlewares = [];
  }

  /**
   * Returns current state.
   */
  getState() {
    return this.state;
  }

  /**
   * Returns current state.
   * Throws error if state has not been initialised.
   */
  getStateOrThrow() {
    if (!this.state)
      throw new Error(
        "Attempting to access non-initialised state. Did you call setInitialState?"
      );

    return this.state;
  }

  /**
   * Initialises store state.
   * This needs to be called before setState() can be used.
   * @param setter State setter
   */
  setInitialState(setter: (prevState?: TState) => TState) {
    const newState = setter(this.state);

    this.publish("@init", undefined, newState);
  }

  /**
   * Set the state of the store.
   * Avoid mutating state, return new object.
   * @param setter State setter
   */
  setState(setter: (prevState: TState) => TState) {
    if (!this.state)
      throw new Error(
        "Attempting to access non-initialised state. Did you call setInitialState?"
      );

    const prevState = this.state;
    const newState = setter(this.state);

    if (newState !== prevState) this.publish("change", prevState, newState);
    else
      console.log(
        "State did not change. Did you mutate the existing state object?"
      );
  }

  /**
   * Subscribee to change events. Handler is triggered
   * when the store state changes.
   * @param event Event name
   * @param handler Callback function
   */
  subscribe(event: EventType, handler: Handler<TState>) {
    if (event === "@init" && this.state)
      throw new Error(
        'Attempting to subscribe to "@init" event after state has been initialised.'
      );

    this.handlers[event] = this.handlers[event] || [];
    this.handlers[event].push(handler);
  }

  /**
   * Remove event subscription.
   * @param event Event name
   * @param handler Callback function
   */
  unsubscribe(event: EventType, handler: Handler<TState>) {
    if (this.handlers[event]) {
      this.handlers[event] = this.handlers[event].filter((h) => h != handler);
    }
  }

  /**
   * Publish event. This notifies all subscribers.
   * @param event Event name
   * @param prevState Previous state
   * @param state Current state
   */
  private publish(
    event: EventType,
    prevState: TState | undefined,
    state: TState
  ) {
    const notifyHandlers = (prevState: TState | undefined, state: TState) => {
      if (this.handlers[event]) {
        this.handlers[event].forEach((handler) => {
          handler(prevState, state);
        });
      }
    };

    if (this.middlewares.length) {
      let current = -1;

      const next = (state: TState) => {
        if (current < this.middlewares.length - 1) {
          this.middlewares[++current](prevState, state, next);
        } else {
          this.state = state;
          notifyHandlers(prevState, state);
        }
      };

      next(state);
    } else {
      this.state = state;
      notifyHandlers(prevState, state);
    }
  }

  /**
   * Register new middle function. This is called before
   * every state change. Middleware can modify state
   * before it is updated.
   * @param middleware Middleware function
   */
  registerMiddleware(middleware: Middleware<TState>): void {
    this.middlewares.push(middleware);
  }
}
