import { TimesheetTemplate } from "../Models/TimesheetTemplate";
import { TimesheetType } from "../Models/TimesheetType";
import { getTimesheetIconInfo } from "../Helpers/GetTimesheetIconInfo";
import { upperCaseFirstChar } from "../Helpers/StringHelpers";
import { TimesheetService } from "../Services/TimesheetService";
import {
  CalendarInfoDays,
  CalendarDay,
  CalendarInfo,
} from "../Models/CalendarInfo";
import { isTimesheetPeriodLocked } from "../Helpers/TimesheetPeriodHelper";

export function render(
  calendarInfo: CalendarInfo,
  timesheetTypes: TimesheetType[],
  timesheetModalHelper: TimesheetService,
  isMobile: boolean
) {
  const isLocked = isTimesheetPeriodLocked(
    calendarInfo.TimesheetPeriodStatusCode
  );

  renderFavourites(calendarInfo.Favourites, timesheetTypes, isLocked, isMobile);
  bindDragAndDrop(timesheetModalHelper, calendarInfo.CurrentPeriodDays);
  bindCollapseButton();
}

function renderFavourites(
  timesheetTemplates: TimesheetTemplate[],
  timesheetTypes: TimesheetType[],
  isPeriodLocked: boolean,
  isMobile: boolean
): void {
  const $outerContainer = $(".favourite-list-wrapper");
  const $list = $outerContainer.find("#divFavList");
  const $newList = $("<div></div>");

  if (!timesheetTemplates.length) {
    $outerContainer.hide();
    return;
  }

  for (var k = 0; k < timesheetTemplates.length; k++) {
    const timesheetTemplate = timesheetTemplates[k];
    const timesheetTypeId = timesheetTemplate.TimesheetTypeId;

    let favouriteDescription = "";
    let favouriteCostCode = "";
    let timesheetTypeForFavorite: TimesheetType | null = null;
    let extraDiv = "";

    let dynamicDivIdForDrag =
      "divDraggable" + "_" + timesheetTemplate.TemplateId;

    for (var l = 0; l < timesheetTypes.length; l++) {
      if (
        timesheetTypes[l].TimesheetTypeId == timesheetTemplate.TimesheetTypeId
      ) {
        timesheetTypeForFavorite = timesheetTypes[l];
        break;
      }
    }

    if (!timesheetTypeForFavorite) {
      toastr.error(
        `Something went wrong. Failed to find timesheetTypeForFavorite for "${timesheetTypeId}".`
      );
      continue;
    }

    let iconInfo = getTimesheetIconInfo(timesheetTypeForFavorite);

    let favouriteImageURL = iconInfo.imagePath;
    let favoriteImageSpanClass = iconInfo.backgroundClass;

    if (!timesheetTemplate.CostCode)
      favouriteCostCode = timesheetTemplate.Code ?? "";
    else
      favouriteCostCode =
        timesheetTemplate.Code + " / " + timesheetTemplate.CostCode;

    if (
      timesheetTemplate.Description &&
      timesheetTemplate.Description != "null"
    ) {
      favouriteDescription = timesheetTemplate.Description ?? "";
    } else {
      favouriteDescription = timesheetTemplate.Title ?? "";
    }

    if (k == 5 || k == 11 || k == 17) extraDiv = "<div class='clearfix'></div>";
    else extraDiv = "";

    let favouriteCardHTML = `
      <div class="favourite-card col-lg-2 col-sm-4 col-xs-12">
        <div id="${dynamicDivIdForDrag}"
          class="favourite-card--item alert alert-white fade in ${
            isPeriodLocked ? "disabled" : ""
          }"
          style="cursor:move;"
          data-timesheetTypeId="${timesheetTypeId}"
          data-timesheetTemplateId="${timesheetTemplate.TemplateId}"
          data-timesheetTypeDraggable>
             ${
               isMobile
                 ? `<button aria-label="Remove favourite ${favouriteDescription}" class="close-favourite close" style="font-size:45px;" data-dismiss="col-lg-2" data-removeFavourite="${timesheetTemplates[k].TemplateId}">×</button>`
                 : `<button aria-label="Remove favourite ${favouriteDescription}" class="close-favourite close" data-dismiss="col-lg-2" data-removeFavourite="${timesheetTemplates[k].TemplateId}">×</button>`
             }
            <span class="work-icon pull-left">
              <span class="dragcheck ${favoriteImageSpanClass}">
                <img src="${favouriteImageURL}"/ >
              </span>
            </span>
            <h6 class="text-center no-margin" >
              <strong class="slate-gray" id ="${
                !isMobile ? "spnJobCostcode" : ""
              }">${favouriteCostCode}</strong>
            </h6>
            <span class="meeting-heading text-center text-overflow-web">${favouriteDescription}</span>
          </div>
        </div>
        ${extraDiv}`;

    $newList.append(favouriteCardHTML);
  }

  $list.html($newList.html());

  $outerContainer.show();
}

function bindCollapseButton() {
  $(document).off("click", "[data-toggleFavouriteList]");

  $(document).on("click", "[data-toggleFavouriteList]", function (e) {
    e.preventDefault();

    const widget = $(this).parents(".widget").eq(0);
    const body = widget.find(".widget-body");
    const button = $(this).find("i");
    const down = "fa-plus";
    const up = "fa-minus";
    const slidedowninterval = 300;
    const slideupinterval = 200;

    if (widget.hasClass("collapsed")) {
      if (button) {
        button.addClass(up).removeClass(down);
      }

      widget.removeClass("collapsed");

      body.slideUp(0, () => {
        body.slideDown(slidedowninterval);
      });
    } else {
      if (button) {
        button.addClass(down).removeClass(up);
      }

      body.slideUp(slideupinterval, () => {
        widget.addClass("collapsed");
      });
    }
  });
}

function bindDragAndDrop(
  timesheetModalHelper: TimesheetService,
  dayInfo: CalendarInfoDays
): void {
  $("[data-timesheetTypeDraggable]").draggable({
    revert: "invalid",
    helper: "clone",
    opacity: 0.7,
  });

  $("[data-timesheetTypeDroppable]").droppable({
    accept: (d: JQuery) => {
      return typeof d.attr("data-timesheetTypeDraggable") !== "undefined";
    },
    greedy: true,
    classes: {
      "ui-droppable-active": "drag-highlight",
    },
    drop: function (_, ui) {
      const $source = ui.draggable;

      let dayId = $(this).attr("data-dayId");

      if (!dayId)
        return toastr.error("Something went wrong. Failed to find dayId.");

      dayId = dayId.toLowerCase();

      const workDateForDrag = dayInfo[upperCaseFirstChar(dayId) as CalendarDay];

      if (!workDateForDrag)
        return toastr.error(
          "Something went wrong. Failed to find workDateForDrag."
        );

      const timesheetTemplateId = $source.attr("data-timesheetTemplateId");

      if (!timesheetTemplateId)
        return toastr.error(
          "Something went wrong. Failed to find timesheetTemplateId."
        );

      timesheetModalHelper.addTimesheetEntryFromTemplate(
        +timesheetTemplateId,
        workDateForDrag
      );
    },
  });
}
