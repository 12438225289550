import {
  createSingleCallLimiter,
  hideLoader,
  isCancelled,
  showLoader,
} from "./ApiHelper";
import { AutocompleteOption } from "../Models/AutocompleteOption";
import { CalendarService } from "../Services/CalendarService";
import { TeamMemberEmployeeForList } from "../Models/TeamMemberEmployee";
import { TimesheetApi } from "../Services/TimesheetApi";

export const setup = (
  api: TimesheetApi,
  calendarService: CalendarService,
  $el: JQuery<HTMLElement>
) => {
  const lastOnly = createSingleCallLimiter();

  $el.autocomplete({
    source: async (
      request: { term: string },
      response: (val: AutocompleteOption[]) => void
    ) => {
      if (!request.term) return;

      showLoader();

      const result = await lastOnly((cancellationToken) =>
        api.getEmployeeListForAutoComplete(request.term, { cancellationToken })
      );

      // Ignore cancelled requests
      if (isCancelled(result)) return;

      hideLoader();

      if (result.length) {
        response(
          $.map(result, (item) => {
            return {
              label:
                item.GivenNames + " " + item.Surname + "/" + item.EmployeeCode,
              val: JSON.stringify(item),
            };
          })
        );
      } else {
        response([{ label: "No Records Found", val: "-1" }]);
      }
    },
    select: (_, ui) => {
      if (ui.item.val == -1) {
        return false;
      } else {
        $("#emp_search_box").val("");

        const item: TeamMemberEmployeeForList = JSON.parse(ui.item.val);

        calendarService.showEmployeeTimesheet(
          item.AccountName,
          item.EmployeeCode,
          item.GivenNames + " " + item.Surname
        );

        return false;
      }
    },
  });
};
