import { diff, patch } from "virtual-dom";
import VNode from "virtual-dom/vnode/vnode";
import VText from "virtual-dom/vnode/vtext";
import htmlToVdom from "html-to-vdom";

declare global {
  interface JQuery {
    vhtml: typeof vhtml;
  }
}

const convertHTML = htmlToVdom({
  VNode: VNode,
  VText: VText,
});

export default function init() {
  jQuery.fn.extend({ vhtml });
}

export function vhtml(this: any, html: string) {
  return this.map(function (this: any) {
    return _vhtml(this, html);
  });
}

function _vhtml(element: any, html: any) {
  if (element.innerHTML) {
    html = element.outerHTML.replace(element.innerHTML, html);
  } else {
    html = element.outerHTML.replace("</", `${html}</`);
  }

  const oldTree = convertHTML(element.outerHTML);
  const newTree = convertHTML(html);
  const patches = diff(oldTree, newTree);

  return patch(element, patches);
}
