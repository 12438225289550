import isEqual from "lodash.isequal";
import { RootState } from "../../Models/RootState";
import { Store } from "../../Helpers/Store";
import { EditProfileForm, InvalidFormItem } from "../../Models/EditProfileForm";

const $form = $("#EditProfile_Form");
const $employeeCodeInput = $("#txtEmployeeCode");
const $employeeNameInput = $("#txtEmployeeName");
const $currentWeekInput = $("#chkCurrentWeek");
const $costCodeInput = $("#txtCostCode");

export const initialise = (store: Store<RootState>) => {
  setup(store);

  store.subscribe("@init", (ps, s) => render(ps, s, true));
  store.subscribe("change", (ps, s) => render(ps, s, false));
};

const setup = (store: Store<RootState>) => {
  $form.parent(".modal-content").draggable({ handle: ".modal-header" });

  $(document).on("change", "#EditProfile_Form input", () =>
    notifyStoreOfChange(store)
  );
};

const render = (
  prevState: RootState | undefined,
  state: RootState,
  firstRender: boolean
) => {
  const formState = state.Forms.EditProfile;

  // Don't bother re-rendering if state hasn't changed
  if (isEqual(prevState?.Forms.EditProfile, formState) && !firstRender) {
    return;
  }

  if (formState) {
    $employeeCodeInput.val(formState.EmployeeCode);
    $employeeNameInput.val(formState.EmployeeName);
    $currentWeekInput.prop("checked", formState.DefaultTSCurrentWeek);
    $costCodeInput.val(formState.DefaultTSCostCode);

    // Reset validation
    $form.find(".form-group.has-error").removeClass("has-error");
    $form.find("[data-validationError]").remove();

    // Show any validation errors
    formState.Validation.InvalidItems.forEach((validationError) => {
      const $el = $(validationError.Selector);
      $el.next("[data-validationError]").remove();
      $el.after(
        `<small class="help-block" data-validationError>${validationError.Message}</small>`
      );
      $el.closest(".form-group").addClass("has-error");
    });
  }
};

const validateForm = (formState: EditProfileForm): EditProfileForm => {
  const validationErrors: InvalidFormItem[] = [];

  const costCodeValue = $costCodeInput.val()?.toString();

  if (costCodeValue && !/^[a-zA-Z0-9]+$/.test(costCodeValue ?? "")) {
    validationErrors.push({
      Selector: "#txtCostCode",
      Message: "Special characters are not allowed.",
    });
  }

  return {
    ...formState,
    Validation: { InvalidItems: validationErrors },
  };
};

const notifyStoreOfChange = (store: Store<RootState>) => {
  store.setState((state) => {
    if (!state.Forms.EditProfile) return state;

    return {
      ...state,
      Forms: {
        ...state.Forms,
        EditProfile: {
          ...validateForm(state.Forms.EditProfile),
          DefaultTSCurrentWeek: $currentWeekInput.is(":checked"),
          DefaultTSCostCode: $costCodeInput.val()?.toString() ?? "",
        },
      },
    };
  });
};
