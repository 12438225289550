import { CalendarDay } from "../Models/CalendarInfo";

export const DayOfWeek = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

/**
 * Subtract days from Date
 * @param date Date
 * @param days Days to subtract
 */
export const subtractDays = (date: Date, days: number) => {
  let d = new Date(date.getTime());
  d.setDate(d.getDate() - Math.abs(days));
  return d;
};

/**
 * Add days from Date
 * @param date Date
 * @param days Days to subtract
 */
export const addDays = (date: Date, days: number) => {
  let d = new Date(date.getTime());
  d.setDate(d.getDate() + Math.abs(days));
  return d;
};

/**
 * Gets next Sunday of current week
 * @param d Date
 */
export const getEndOfWeek = (d: Date) => {
  d = new Date(d);

  const day = d.getDay();
  const diff = d.getDate() + 7 - (day == 0 ? 7 : day); // adjust when day is sunday

  return new Date(d.setDate(diff));
};

/**
 * Gets previous Monday of current week
 * @param d Date
 */
export const getStartOfWeek = (d: Date) => {
  d = new Date(d);

  const day = d.getDay();
  const diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday

  return new Date(d.setDate(diff));
};

/**
 * Parses dates in dd-MMM-yyyy format:
 * E.g. 06-Jan-2020
 * @param string Date string
 */
export const parseCommonDate = (dateStr: string): Date => {
  if (!dateStr) throw new Error("Cannot parse empty string as Date");

  const date = new Date(2000, 0, 1, 0, 0, 0, 0);
  const parts = dateStr.split("-");

  const months = getShortMonthNames();

  date.setFullYear(+parts[2]);
  date.setMonth(months.indexOf(parts[1]));
  date.setDate(+parts[0]);
  date.setHours(0, 0, 0, 0);

  return date;
};

/**
 * Format date string to DD-MMM-YYYY.
 * E.g. 06-Jan-2020
 * @param date Date
 */
export const formatDate = (date: Date): string => {
  return (
    date.getDate().toString().padStart(2, "0") +
    "-" +
    getShortMonthNames()[date.getMonth()] +
    "-" +
    date.getFullYear()
  );
};

/**
 * Shorter date string format.
 * E.g. 06-Jan-20
 * @param date Date
 */
export const formatShortDate = (date: Date): string => {
  return (
    date.getDate().toString().padStart(2, "0") +
    "-" +
    getShortMonthNames()[date.getMonth()] +
    "-" +
    date.getFullYear().toString().slice(-2)
  );
};

export const getShortMonthNames = () => {
  return [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
};

/**
 * List of day names matching JS Date indexes
 */
export const getDaysOfWeekNames = () => {
  return [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
};

/**
 * List of CalendarDay's starting Monday
 */
export const getCalendarInfoDaysFromMonday = (): CalendarDay[] => {
  return [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
};

export const getShortMonthName = (month: number): string => {
  return getShortMonthNames()[month] ?? "";
};
