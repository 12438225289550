import { TimesheetType } from "../../../Models/TimesheetType";
import { TimesheetForm } from "../../../Models/TimesheetForm";
import { formatMoney } from "../../../Helpers/StringHelpers";

export const renderTimesheetTypeOptionsForDay = (
  $container: JQuery<HTMLElement>,
  wageType: string,
  timesheetType: TimesheetType,
  formState: TimesheetForm
) => {
  const { addColumn, closeRow } = createFormGroupManager($container);
  const showAdditionalQtys =
    wageType !== "S" &&
    (timesheetType.OvertimeQtyAccess !== "H" ||
      timesheetType.PublicHolidayQtyAccess !== "H" ||
      timesheetType.ExtraQtyAccess !== "H");

  const normalQty =
    formState.DayData.NormalQty?.toString() ??
    timesheetType.NormalQtyDefault?.toString() ??
    "";
  const normalRate =
    formState.DayData.NormalRate?.toString() ??
    timesheetType.NormalRateDefault?.toString() ??
    "";

  // Check if just the NormalQty entry input is shown
  if (timesheetType.NormalQtyAccess !== "H" && !showAdditionalQtys) {
    addColumn("dayNormalQty", normalQty, 4, {
      disabled: timesheetType.NormalQtyAccess === "R",
      controlLabel: "Normal Quantity",
    });

    // Check if all hours entry inputs are shown
  } else if (showAdditionalQtys) {
    // Label displayed of the left of inputs
    let groupLabel = "Hours";

    if (timesheetType.NormalQtyAccess !== "H") {
      addColumn("dayNormalQty", normalQty, 2, {
        disabled: timesheetType.NormalQtyAccess === "R",
        rowClass: "margin-bottom-5",
        controlLabel: groupLabel,
        label: "Normal",
      });
      groupLabel = "";
    }

    if (timesheetType.ExtraQtyAccess !== "H" && wageType !== "S") {
      addColumn(
        "dayExtraQty",
        formState.DayData.ExtraQty?.toString() ?? "",
        2,
        {
          disabled: timesheetType.ExtraQtyAccess === "R",
          rowClass: "margin-bottom-5",
          controlLabel: groupLabel,
          label: "Overtime",
        }
      );
      groupLabel = "";
    }

    if (timesheetType.OvertimeQtyAccess !== "H" && wageType !== "S") {
      addColumn(
        "dayOvertimeQty",
        formState.DayData.OvertimeQty?.toString() ?? "",
        2,
        {
          disabled: timesheetType.ExtraQtyAccess === "R",
          rowClass: "margin-bottom-5",
          controlLabel: groupLabel,
          label: "Double Time",
        }
      );
      groupLabel = "";
    }

    if (timesheetType.PublicHolidayQtyAccess !== "H" && wageType !== "S") {
      addColumn(
        "dayPublicHolidayQty",
        formState.DayData.PublicHolidayQty?.toString() ?? "",
        2,
        {
          disabled: timesheetType.ExtraQtyAccess === "R",
          rowClass: "margin-bottom-5",
          controlLabel: groupLabel,
          label: "Public Holiday",
        }
      );
      groupLabel = "";
    }

    closeRow();
  }

  // Show calculated value field if both Qty and Rate are shown
  if (
    timesheetType.NormalQtyAccess !== "H" &&
    timesheetType.NormalRateAccess !== "H"
  ) {
    const value = (formState.DayData.NormalQty ?? 0) * (+normalRate ?? 0);

    addColumn("dayNormalValue", formatMoney(value), 4, {
      disabled: true,
      controlLabel: "Amount",
    });
  }

  if (timesheetType.NormalRateAccess !== "H") {
    addColumn("dayNormalRate", normalRate, 4, {
      controlLabel: "Normal Rate",
    });
  }
};

/**
 * Returns function that manages creation of form columns.
 * Ensures that each column will fit and not exceed 12 columns per row.
 * @param $container
 */
const createFormGroupManager = ($container: JQuery<HTMLElement>) => {
  let colsRemaining = 12;
  let $formGroup = createFormGroup();

  $container.append($formGroup);

  return {
    addColumn: (
      name: string,
      value: string,
      cols: number = 4,
      options?: {
        disabled?: boolean;
        rowClass?: string;
        controlLabel?: string;
        label?: string;
      }
    ) => {
      const totalCols = cols + (options?.controlLabel ? 2 : 0);

      if (colsRemaining - totalCols < 0) {
        $formGroup = createFormGroup();
        $container.append($formGroup);
        colsRemaining = 12;
      } else {
        colsRemaining -= totalCols;
      }

      createFormGroupCol($formGroup, name, value, {
        ...options,
        rowClass: (options?.rowClass ?? "") + " col-lg-" + cols,
      });
    },
    closeRow: () => {
      $formGroup = createFormGroup();
      $container.append($formGroup);
      colsRemaining = 12;
    },
  };
};

const createFormGroup = () => $('<div class="form-group"></div>');

const createFormGroupCol = (
  $formGroup: JQuery<HTMLElement>,
  name: string,
  value: string,
  options?: {
    disabled?: boolean;
    rowClass?: string;
    controlLabel?: string;
    label?: string;
  }
) => {
  const $row = $(`<div class="${options?.rowClass ?? ""}">
      <input aria-label="Enter ${options?.controlLabel} Hours" id="${name}" name="${name}" class="form-control focus-inputbox" ${
    options?.disabled ? "disabled" : ""
  } value="${value}">
    </div>`);

  if (options?.controlLabel)
    $formGroup.append(
      `<label class="col-lg-2 control-label">${options.controlLabel} :</label>`
    );

  if (options?.label) $row.append(`<label>${options.label}</label>`);

  return $formGroup.append($row);
};
