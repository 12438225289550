import { ApplicationInsights } from "@microsoft/applicationinsights-web";

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: APP_INSIGHTS_KEY,
  },
});
appInsights.loadAppInsights();
const appInsightsUserName = '@User.Identity.Name.Replace("\\", "\\\\")';
appInsights.setAuthenticatedUserContext(
  appInsightsUserName.replace(/[,;=| ]+/g, "_")
);
appInsights.trackPageView();

export default appInsights;
