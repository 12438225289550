import lscache from "lscache";
import { CodeInfo } from "../Models/TimesheetForm";
import { isCancelled } from "./ApiHelper";
import { ApiOptions, TimesheetApi } from "../Services/TimesheetApi";

const pending: Record<string, Promise<any>> = {};

const getCacheKey = (
  accountCode: string,
  workCode: string,
  timesheetTypeId: number
) => `${accountCode}_WORKCODEINFO_V2_${workCode}_${timesheetTypeId}`;

/**
 * Retrieves Work Code info from localstorage cache.
 * @param accountCode
 * @param workCode
 * @param timesheetTypeId
 */
export const getCachedWorkCodeInfo = (
  accountCode: string,
  workCode: string,
  timesheetTypeId: number
): CodeInfo | null => {
  return lscache.get(getCacheKey(accountCode, workCode, timesheetTypeId));
};

/**
 * Retrieves Work Code info either from API or localstorage cache.
 * @param api
 * @param accountCode
 * @param workCode
 * @param timesheetTypeId
 * @param apiOptions TimesheetApi Options
 * @param useCache If true returns cached value, if false will always call server for latest value.
 */
export const getWorkCodeInfo = async (
  api: TimesheetApi,
  accountCode: string,
  workCode: string,
  timesheetTypeId: number,
  apiOptions?: ApiOptions,
  useCache: boolean = true
): Promise<CodeInfo> => {
  const cacheKey = getCacheKey(accountCode, workCode, timesheetTypeId);
  const cachedResult = getCachedWorkCodeInfo(
    accountCode,
    workCode,
    timesheetTypeId
  );

  if (cachedResult && useCache) {
    return cachedResult;
  }

  let promise: Promise<CodeInfo>;

  if (pending[cacheKey]) {
    promise = pending[cacheKey];
  } else {
    promise = api.getCostCodeInfo(
      workCode,
      timesheetTypeId,
      accountCode,
      apiOptions
    );
    pending[cacheKey] = promise;
  }

  const result = await promise;

  delete pending[cacheKey];

  if (!isCancelled(result)) {
    lscache.set(cacheKey, result, 60 * 60 * 24 * 60); // cache for 60 days
  }

  return result;
};
