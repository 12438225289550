import { Store } from "../Helpers/Store";
import { RootState } from "../Models/RootState";
import {
  findFavouriteForTimesheetEntry,
  addFavouriteToState,
  removeFavouriteFromState,
} from "../Helpers/StateHelper";
import { Timesheet } from "../Models/Timesheet";
import { TimesheetTemplate } from "../Models/TimesheetTemplate";
import { callStatusEndpoint } from "../Helpers/ApiHelper";
import { TimesheetForm } from "../Models/TimesheetForm";
import { TimesheetApi } from "./TimesheetApi";

export class FavouriteService {
  constructor(private store: Store<RootState>, private api: TimesheetApi) {}

  async toggleFavouriteByTimesheetRecordId(timesheetRecordId: number) {
    const state = this.store.getStateOrThrow();

    const timesheetEntry = state.CalendarInfo.Timesheets.find(
      (ts) => ts.RecordId === timesheetRecordId
    );

    if (!timesheetEntry) {
      toastr.error("Failed to find corresponding timesheet entry.");
      return;
    }

    const template = findFavouriteForTimesheetEntry(state, timesheetEntry);

    // If we already match a timesheet template (aka favourite), delete it!
    if (template) {
      await this.removeFromFavouriteList(template.TemplateId);
    } else {
      await this.addToFavouriteList(timesheetEntry);
    }
  }

  async addFavouriteFromOpenTimesheet() {
    const state = this.store.getStateOrThrow();
    const formState = state.Forms.Timesheet;

    if (!formState) {
      toastr.error(
        "Failed to find form state (addFavouriteFromOpenTimesheet)."
      );
      return;
    }

    const template = findFavouriteForTimesheetEntry(state, formState);

    // If we already match a timesheet template (aka favourite), delete it!
    if (template) {
      await this.removeFromFavouriteList(template.TemplateId);
    } else {
      await this.addToFavouriteList(formState);
    }
  }

  async addToFavouriteList(timesheet: Timesheet | TimesheetForm) {
    const state = this.store.getStateOrThrow();

    const employeeCode = state.CalendarInfo.EmployeeCode;

    if (!timesheet.TimesheetTypeId) return;

    const timesheetTemplate: TimesheetTemplate = {
      TemplateId: 0,
      EmployeeCode: employeeCode,
      TimesheetTypeId: timesheet.TimesheetTypeId,
      Code: timesheet.Code,
      CostCode: timesheet.CostCode,
      Description: timesheet.Description,
      Title: timesheet.Title,
      ModifiedDate: new Date().toDateString(),
      ModifiedBy: state.SessionInfo.AccountName,
    };

    try {
      const result = await this.api.saveTimesheetTemplateInfo(
        timesheetTemplate,
        { showLoader: true }
      );

      if (!result.Successful) {
        toastr.error("An error occurred: " + result.Status);
        return;
      }

      if (!result.TemplateId) {
        toastr.warning("Unexpected response when saving favourite.");
        return;
      }

      toastr.success("Successfully added favourite.");

      // Add favourite to store
      this.store.setState((state) =>
        addFavouriteToState(state, {
          ...timesheetTemplate,
          TemplateId: result.TemplateId ?? 0,
        })
      );
    } catch (e) {
      toastr.error(e.Status ?? "An unexpected error occurred.");
    }
  }

  async removeFromFavouriteList(templateId: number) {
    const success = await callStatusEndpoint(() =>
      this.api.deleteTimesheetTemplateInfo(templateId.toString(), {
        showLoader: true,
      })
    );

    if (!success) return;

    toastr.success("Successfully removed favourite.");

    this.store.setState((state) => removeFavouriteFromState(state, templateId));
  }
}
