/**
 * Returns element value as string
 * @param $el jQuery element
 */
export const stringVal = ($el: JQuery<HTMLElement>): string | undefined => {
  const value = $el.val();

  if (typeof value === "string") return value;

  return value ? value.toString() : undefined;
};

/**
 * Returns element value as number
 * @param $el jQuery element
 */
export const numberVal = ($el: JQuery<HTMLElement>): number | undefined => {
  const value = $el.val();

  if (typeof value === "number") return value;

  return value ? (isNaN(+value) ? undefined : +value) : undefined;
};
