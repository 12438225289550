export const upperCaseFirstChar = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const lowerCaseFirstChar = (string: string): string =>
  string.charAt(0).toLowerCase() + string.slice(1);

export const formatTotal = (
  value: number | null | undefined,
  stripTrailingZeroes: boolean = false
): string => {
  const result = value ? value.toFixed(2).toString() : "0.00";
  return stripTrailingZeroes ? parseFloat(result).toString() : result;
};

/**
 * Format number as currency string in Australia dollar
 * format. E.g. 1000.02 => $1,000.02
 *
 * @param number  value: source number
 * @param integer decimals: number of trailing decimals
 * @param integer groupLen: length of group; e.g. 1,000,000 = groupLen of 3
 * @param mixed   groupSep: sections delimiter
 * @param mixed   decimalSep: decimal delimiter
 */
export const formatMoney = (
  value: number,
  decimals: number = 2,
  groupLen = 3,
  groupSep = ",",
  decimalSep = "."
) => {
  const re =
    "\\d(?=(\\d{" +
    (groupLen || 3) +
    "})+" +
    (decimals > 0 ? "\\D" : "$") +
    ")";
  const num = value.toFixed(Math.max(0, ~~decimals));

  return (
    "$" +
    (decimalSep ? num.replace(".", decimalSep) : num).replace(
      new RegExp(re, "g"),
      "$&" + (groupSep || ",")
    )
  );
};

/**
 * Converts empty string or undefined to null
 * @param value String
 */
export const emptyToNull = (value: string | null | undefined) => {
  return value === "" || value === null || typeof value === "undefined"
    ? null
    : value;
};
