import isEqual from "lodash.isequal";
import { Store } from "./Store";
import { RootState } from "../Models/RootState";
import { formatDate, parseCommonDate } from "./DateHelper";
import { CalendarService } from "../Services/CalendarService";

const employeeInfoCache: {
  AccountName: string;
  EmployeeCode: string;
  DisplayName: string;
}[] = [];

export const setup = (
  calendarService: CalendarService,
  store: Store<RootState>
) => {
  // Check if history API is available
  if (!window.history) return;

  store.subscribe("@init", (_, state) => initialiseHistoryState(state));
  store.subscribe("change", (prevState, state) =>
    onAppStateChange(prevState, state)
  );

  window.onpopstate = onHistoryStateChange(calendarService, store);
};

type HistoryState = {
  Date?: string;
  AccountName?: string;
};

const onHistoryStateChange =
  (calendarService: CalendarService, store: Store<RootState>) =>
  async (event: PopStateEvent) => {
    const state = store.getState();
    const historyState: HistoryState = event.state;

    if (!state || !historyState) return;

    if (
      historyState.Date &&
      historyState.Date !== formatDate(state.CalendarInfo.StartDate)
    ) {
      await calendarService.moveToWeek(parseCommonDate(historyState.Date));
    }

    if (
      historyState.AccountName &&
      historyState.AccountName !== state.CalendarInfo.AccountName
    ) {
      const employeeInfo = employeeInfoCache.find(
        (e) => e.AccountName === historyState.AccountName
      );

      if (!employeeInfo) {
        // Reload page to pull info from server
        window.location.reload();
        return;
      }

      calendarService.showEmployeeTimesheet(
        historyState.AccountName,
        employeeInfo.EmployeeCode,
        employeeInfo.DisplayName
      );
    }
  };

const initialiseHistoryState = (state: RootState) => {
  const newState = {
    Date: formatDate(state.CalendarInfo.StartDate),
    AccountName: state.CalendarInfo.AccountName,
  };

  history.replaceState(
    newState,
    "",
    window.location.pathname + window.location.search
  );
};

const onAppStateChange = (
  prevState: RootState | undefined,
  state: RootState
) => {
  const path = "/Common/GetEmployeeTimesheet";
  const title = "";

  let newState: HistoryState | null = null;

  // Check if user is viewing another user's timesheet
  if (state.CalendarInfo.EmployeeCode === state.SessionInfo.EmployeeCode)
    return;

  // Check if displayed employee has changed
  if (
    !isEqual(
      prevState?.CalendarInfo.AccountName,
      state.CalendarInfo.AccountName
    )
  ) {
    employeeInfoCache.push({
      AccountName: state.CalendarInfo.AccountName,
      EmployeeCode: state.CalendarInfo.EmployeeCode,
      DisplayName: state.CalendarInfo.EmployeeDisplayName,
    });

    newState = {
      Date: formatDate(state.CalendarInfo.StartDate),
      AccountName: state.CalendarInfo.AccountName,
    };

    // Check if displayed calendar period has changed
  } else if (
    !isEqual(prevState?.CalendarInfo.StartDate, state.CalendarInfo.StartDate)
  ) {
    newState = {
      Date: formatDate(state.CalendarInfo.StartDate),
      AccountName: state.CalendarInfo.AccountName,
    };
  }

  if (newState) {
    history.pushState(newState, title, `${path}?${jQuery.param(newState)}`);
  }
};
