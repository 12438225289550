import {
  PublicClientApplication,
  AuthenticationResult,
  Configuration,
  LogLevel,
  AccountInfo,
  InteractionRequiredAuthError,
  RedirectRequest,
  PopupRequest,
  EndSessionRequest,
  SsoSilentRequest,
  SilentRequest,
} from "@azure/msal-browser";
import appInsights from "../ApplicationInsights";
import { SessionInfoBase } from "../Models/SessionInfo";

const GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0/";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;
const useRedirect = isIE;

/**
 * Configuration class for @azure/msal-browser:
 * https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_config_configuration_.html
 */
const MSAL_CONFIG: Configuration = {
  auth: {
    clientId: AAD_CLIENT_ID,
    authority: AAD_AUTHORITY,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: isIE,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;

        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
};

type GraphQlProfileInfo = {
  "@odata.context": string;
  employeeId: string;
  displayName: string;
  onPremisesSamAccountName: string;
  userPrincipalName: string;
  id: string;
};

/**
 * AuthModule for application - handles authentication in app.
 */
export class AuthModule {
  private myMSALObj: PublicClientApplication; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/classes/_src_app_publicclientapplication_.publicclientapplication.html
  private account: AccountInfo | null; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-common/modules/_src_account_accountinfo_.html
  private loginRedirectRequest: RedirectRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_redirectrequest_.html
  private loginRequest: PopupRequest; // https://azuread.github.io/microsoft-authentication-library-for-js/ref/msal-browser/modules/_src_request_popuprequest_.html
  private silentLoginRequest: SsoSilentRequest;
  private timesheetApiRequest: PopupRequest;
  private profileRedirectRequest: RedirectRequest;
  private profileRequest: PopupRequest;
  private silentProfileRequest: SilentRequest;
  private timesheetApiRedirectRequest: RedirectRequest;
  private timesheetApiSilentRequest: SilentRequest;

  constructor() {
    this.myMSALObj = new PublicClientApplication(MSAL_CONFIG);
    this.account = null;

    // Login requests
    this.loginRequest = {
      scopes: ["openid", "profile", "User.Read"],
    };

    this.loginRedirectRequest = {
      ...this.loginRequest,
      redirectStartPage: window.location.href,
    };

    this.silentLoginRequest = {};

    // Profile requests
    this.profileRequest = {
      scopes: ["User.Read"],
    };

    this.profileRedirectRequest = {
      ...this.profileRequest,
      redirectStartPage: window.location.href,
    };

    this.silentProfileRequest = {
      scopes: ["openid", "profile", "User.Read"],
      forceRefresh: false,
    };

    // Timesheet API requests
    this.timesheetApiRequest = {
      scopes: [
        "api://sage-central/SAGE.Timesheets.ReadWrite",
        "api://sage-central/SAGE.TeamMembers.ReadWrite",
        "api://sage-central/SAGE.WorkCodes.Read",
      ],
    };

    this.timesheetApiRedirectRequest = {
      ...this.timesheetApiRequest,
      redirectStartPage: window.location.href,
    };

    this.timesheetApiSilentRequest = {
      ...this.timesheetApiRequest,
      forceRefresh: false,
    };
  }

  /**
   * Calls getAllAccounts and determines the correct account to sign into, currently defaults to first account found in cache.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-common/docs/Accounts.md
   */
  private getAccount(): AccountInfo | null {
    // need to call getAccount here?
    const currentAccounts = this.myMSALObj.getAllAccounts();

    if (currentAccounts === null) {
      console.log("No accounts detected");
      return null;
    }

    if (currentAccounts.length > 1) {
      // Add choose account code here
      console.log("Multiple accounts detected");

      toastr.warning("Multiple logged in accounts detected", "Warning");

      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }

    return null;
  }

  async getSessionInfo(): Promise<SessionInfoBase | null> {
    const account = this.getAccount();

    if (!account) return null;

    const accessToken = await this.getProfileTokenPopup();

    if (!accessToken) return null;

    const response = await fetch(
      GRAPH_ENDPOINT +
        "me?$select=employeeId,displayName,onPremisesSamAccountName,userPrincipalName,id",
      {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      }
    );

    if (!response.ok) return null;

    const data = (await response.json()) as GraphQlProfileInfo;

    return {
      AccountName: data.onPremisesSamAccountName?.length
        ? data.onPremisesSamAccountName
        : data.userPrincipalName.substring(
            0,
            data.userPrincipalName.indexOf("@")
          ),
      EmployeeCode: data.employeeId,
      EmployeeName: data.displayName,
    };
  }

  /**
   * Checks whether we are in the middle of a redirect and handles state accordingly. Only required for redirect flows.
   *
   * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/initialization.md#redirect-apis
   */
  async loadAuthModule(): Promise<AccountInfo | undefined> {
    return this.myMSALObj
      .handleRedirectPromise()
      .then((resp: AuthenticationResult | null) => {
        return this.handleResponse(resp);
      });
  }

  /**
   * Handles the response from a popup or redirect. If response is null, will check if we have any accounts and attempt to sign in.
   * @param response
   */
  async handleResponse(
    response: AuthenticationResult | null
  ): Promise<AccountInfo | undefined> {
    if (response !== null) {
      this.account = response.account;
    } else {
      this.account = this.getAccount();
    }

    if (this.account) {
      return this.account;
    }
  }

  /**
   * Calls ssoSilent to attempt silent flow. If it fails due to interaction required error, it will prompt the user to login using popup.
   * @param request
   */
  async attemptSsoSilent(): Promise<AccountInfo | undefined> {
    this.silentLoginRequest.loginHint = this.account?.username;

    try {
      await this.myMSALObj.ssoSilent(this.silentLoginRequest);
    } catch (error) {
      console.error("Silent Error: " + error);
      appInsights.trackException({
        error: error as any,
      });
      if (error instanceof InteractionRequiredAuthError) {
        return this.login("loginPopup");
      }

      throw error;
    }

    this.account = this.getAccount();
    return this.account ?? undefined;
  }

  /**
   * Calls loginPopup or loginRedirect based on given signInType.
   * @param signInType
   */
  async login(
    signInType?: "loginRedirect" | "loginPopup" | "auto"
  ): Promise<AccountInfo | undefined> {
    if (!signInType || signInType == "auto")
      signInType = useRedirect ? "loginRedirect" : "loginPopup";

    if (signInType === "loginPopup") {
      const response = await this.myMSALObj.loginPopup(this.loginRequest);
      return this.handleResponse(response);
    } else if (signInType === "loginRedirect") {
      await this.myMSALObj.loginRedirect(this.loginRedirectRequest);
    }
  }

  /**
   * Logs out of current account.
   */
  logout(): void {
    let account: AccountInfo | undefined;

    if (this.account) {
      account = this.account;
    }

    const logOutRequest: EndSessionRequest = {
      account,
    };

    this.myMSALObj.logoutRedirect(logOutRequest);
  }

  getProfileToken(): Promise<string | null> {
    return useRedirect
      ? this.getProfileTokenRedirect()
      : this.getProfileTokenPopup();
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive redirect.
   */
  async getProfileTokenRedirect(): Promise<string | null> {
    if (this.account) {
      this.silentProfileRequest.account = this.account;
    }

    return this.getTokenRedirect(
      this.silentProfileRequest,
      this.profileRedirectRequest
    );
  }

  /**
   * Gets the token to read user profile data from MS Graph silently, or falls back to interactive popup.
   */
  async getProfileTokenPopup(): Promise<string | null> {
    if (this.account) {
      this.silentProfileRequest.account = this.account;
    }

    return this.getTokenPopup(this.silentProfileRequest, this.profileRequest);
  }

  getTimesheetApiToken(): Promise<string | null> {
    return useRedirect
      ? this.getTimesheetApiTokenRedirect()
      : this.getTimesheetApiTokenPopup();
  }

  async getTimesheetApiTokenRedirect(): Promise<string | null> {
    if (this.account) {
      this.timesheetApiSilentRequest.account = this.account;
    }

    return this.getTokenRedirect(
      this.timesheetApiSilentRequest,
      this.timesheetApiRedirectRequest
    );
  }

  async getTimesheetApiTokenPopup(): Promise<string | null> {
    if (this.account) {
      this.timesheetApiSilentRequest.account = this.account;
    }

    return this.getTokenPopup(
      this.timesheetApiSilentRequest,
      this.timesheetApiRequest
    );
  }

  /**
   * Gets a token silently, or falls back to interactive popup.
   */
  private async getTokenPopup(
    silentRequest: SilentRequest,
    interactiveRequest: PopupRequest
  ): Promise<string | null> {
    try {
      const response: AuthenticationResult =
        await this.myMSALObj.acquireTokenSilent(silentRequest);

      return response.accessToken;
    } catch (e) {
      console.log("silent token acquisition fails.");
      appInsights.trackException({
        error: e as any,
      });
      if (e instanceof InteractionRequiredAuthError) {
        console.log("acquiring token using redirect");

        return this.myMSALObj
          .acquireTokenPopup(interactiveRequest)
          .then((resp) => {
            return resp.accessToken;
          })
          .catch((err) => {
            console.error(err);
            return null;
          });
      } else {
        console.error(e);
      }
    }

    return null;
  }

  /**
   * Gets a token silently, or falls back to interactive redirect.
   */
  private async getTokenRedirect(
    silentRequest: SilentRequest,
    interactiveRequest: RedirectRequest
  ): Promise<string | null> {
    try {
      const response = await this.myMSALObj.acquireTokenSilent(silentRequest);
      return response.accessToken;
    } catch (e) {
      console.log("silent token acquisition fails.");

      if (e instanceof InteractionRequiredAuthError) {
        console.log("acquiring token using redirect");

        this.myMSALObj
          .acquireTokenRedirect(interactiveRequest)
          .catch(console.error);
      } else {
        console.error(e);
      }
    }

    return null;
  }
}
