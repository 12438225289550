import isEqual from "lodash.isequal";
import { Store } from "../Helpers/Store";
import { RootState } from "../Models/RootState";
import { Timesheet } from "../Models/Timesheet";
import { getWorkCodeInfo } from "../Helpers/WorkCodeInfo";
import { validCodeInfo } from "../Models/TimesheetForm";
import { generateWarningsForTimesheetRecord } from "../Helpers/TimesheetEntryWarnings";
import { findTimesheetTypeById } from "../Helpers/TimesheetTypeHelper";
import { TimesheetType } from "../Models/TimesheetType";
import { TimesheetApi } from "../Services/TimesheetApi";
import appInsights from "../ApplicationInsights";

/**
 * Populates timesheet entry warnings by making API calls
 * to the GetCostCode endpoint and running the
 * `generateWarningsForTimesheetRecord` validation helper.
 * @param store Global store
 */
export const createMiddleware = (
  api: TimesheetApi,
  store: Store<RootState>
) => {
  let timeout: number = 0;

  store.registerMiddleware(async (prevState, state, next) => {
    if (!state.CalendarInfo.Timesheets?.length) return next(state);

    if (
      isEqual(
        prevState?.CalendarInfo?.Timesheets,
        state.CalendarInfo.Timesheets
      )
    ) {
      return next(state);
    }

    next(state);

    // Function to grab timesheet metadata and update store if required.
    // This will run after the current state update.
    // Note: this function will be debounced below preventing it
    //       from being called more than once per second.
    const execute = async () => {
      try {
        const timesheetMetaList = await processEntries(
          api,
          state.TimesheetTypes,
          state.CalendarInfo.EmployeeInfo.AccountName,
          state.CalendarInfo.EmployeeInfo.BusinessUnitCode,
          state.CalendarInfo.Timesheets
        );

        if (timesheetMetaList.length) {
          store.setState((prevState) => ({
            ...prevState,
            CalendarInfo: {
              ...prevState.CalendarInfo!,
              Timesheets: prevState.CalendarInfo!.Timesheets.map(
                (timesheet) => {
                  const meta = timesheetMetaList.find(
                    (m) => m.RecordId === timesheet.RecordId
                  );

                  return {
                    ...timesheet,
                    _validationWarnings: meta?.ValidationWarnings,
                    _metadataProcessed: true,
                  };
                }
              ),
            },
          }));
        }
      } catch (e) {
        appInsights.trackException({
          error: e as any,
        });
        console.error(e);
      }
    };

    // Cancel previous execution and schedule new one
    window.clearTimeout(timeout);
    timeout = window.setTimeout(() => execute(), 1000);
  });
};

type TimesheetMeta = {
  RecordId: number;
  ValidationWarnings: string[];
};

const processEntries = (
  api: TimesheetApi,
  timesheetTypes: TimesheetType[],
  accountName: string,
  employeeBusinessUnitCode: string,
  timesheets: Timesheet[]
): Promise<TimesheetMeta[]> => {
  const result = timesheets
    .filter((ts) => !ts._metadataProcessed)
    .map((ts) =>
      processEntry(api, timesheetTypes, accountName, employeeBusinessUnitCode, {
        ...ts,
      })
    );

  return Promise.all(result);
};

const processEntry = async (
  api: TimesheetApi,
  timesheetTypes: TimesheetType[],
  accountName: string,
  employeeBusinessUnitCode: string,
  timesheet: Timesheet
): Promise<TimesheetMeta> => {
  if (!timesheet.EmployeeCode || !timesheet.Code) {
    return {
      RecordId: timesheet.RecordId,
      ValidationWarnings: [],
    };
  }

  const codeInfo = await getWorkCodeInfo(
    api,
    accountName,
    timesheet.Code,
    timesheet.TimesheetTypeId
  );

  const timesheetType = findTimesheetTypeById(
    timesheetTypes,
    timesheet.TimesheetTypeId
  );

  if (!timesheetType || !validCodeInfo(codeInfo)) {
    return {
      RecordId: timesheet.RecordId,
      ValidationWarnings: [],
    };
  }

  return {
    RecordId: timesheet.RecordId,
    ValidationWarnings: generateWarningsForTimesheetRecord(
      employeeBusinessUnitCode,
      codeInfo,
      timesheet.CostCode,
      timesheetType
    ),
  };
};
