import appInsights from "../ApplicationInsights";
import {
  ApiStatusResult,
  CancellationToken,
  createCancellationToken,
} from "../Services/TimesheetApi";

export type CancelledRequest = {
  isCancelled: boolean;
};

/**
 * Helper used to simplify calling endpoints that return
 * a ApiStatusResult response. Handles error notifications.
 * @param execute Executes API call
 * @returns boolean True if success, false if fail
 */
export const callStatusEndpoint = async (
  execute: () => Promise<ApiStatusResult>
) => {
  try {
    const result = await execute();

    if (!result.Successful) {
      toastr.error("An error occurred: " + result.Status);
      return false;
    }

    return true;
  } catch (e) {
    toastr.error((e as any).Status ?? "An unexpected error occurred.");
    appInsights.trackException({
      error: e as any,
    });
  }

  return false;
};

/**
 * Shows fullscreen non-dismissable error message
 */
export const showFatalError = () => {
  $(".fatal-application-error").css("display", "flex");
};

/**
 * Shows fullscreen non-dismissable loading message
 */
export const showLoader = () => $(".fullscreen-loader").addClass("is-visible");

/**
 * Hides fullscreen non-dismissable loading message
 */
export const hideLoader = () =>
  $(".fullscreen-loader").removeClass("is-visible");

/**
 * Shows login screen
 */
export const showLoginScreen = () => $(".login-screen").css("display", "flex");

/**
 * Hides login screen
 */
export const hideLoginScreen = () => $(".login-screen").css("display", "none");

/**
 * Helper that limits calls to one at a time.
 */
export const createSingleCallLimiter = () => {
  let cancellationToken: CancellationToken | null = null;

  return async <T>(
    callback: (cancellationToken: CancellationToken) => Promise<T>
  ): Promise<T | CancelledRequest> => {
    // Cancel previous request
    if (cancellationToken) cancellationToken.cancel();

    // Create new cancellation token
    cancellationToken = createCancellationToken();

    try {
      return await callback(cancellationToken);
    } catch (e) {
      // Ignore aborted calls
      if ((e as any).textStatus === "abort") return { isCancelled: true };
      appInsights.trackException({
        error: e as any,
      });
      // Rethrow other errors
      throw e;
    }
  };
};

/**
 * Check for cancelled requests
 * @param result boolean Cancelled?
 */
export const isCancelled = <T>(
  result: T | CancelledRequest
): result is CancelledRequest => {
  return (result as CancelledRequest).isCancelled;
};
