import isEqual from "lodash.isequal";
import { RootState } from "../../../Models/RootState";
import { InvalidFormItem } from "../../../Models/BaseForm";
import { countDecimals } from "../../../Helpers/NumberHelper";
import { validCodeInfo, TimesheetForm } from "../../../Models/TimesheetForm";
import { setFormState } from "../../../Helpers/StateHelper";
import { Store } from "../../../Helpers/Store";
import { findTimesheetTypeById } from "../../../Helpers/TimesheetTypeHelper";
import { getCalendarInfoDaysFromMonday } from "../../../Helpers/DateHelper";
import { lowerCaseFirstChar } from "../../../Helpers/StringHelpers";
import { uniqBy } from "../../../Helpers/ArrayHelper";

export const createMiddleware = (store: Store<RootState>) => {
  store.registerMiddleware((prevState, state, next) => {
    if (isEqual(prevState?.Forms.Timesheet, state.Forms.Timesheet)) {
      return next(state);
    }

    return next(validateForm(state));
  });
};

const isJobCodeBlacklisted = (jobCode: string) => {
  const blacklist = [
    // Salesforce bucket jobs
    "69002",
    "69003",
    "69004",
    "69005",
    "69006",
    "69007",
  ];

  return blacklist.includes(jobCode.trim());
};

export const validateForm = (state: RootState): RootState => {
  const formState = state.Forms.Timesheet;
  const validationErrors: InvalidFormItem[] = [];
  const validateAll = !!formState?.ValidateAll; // This flag is set when form is submitted
  const isNewRecord = !formState?.RecordId;

  const validate = (key: string) =>
    validateAll || !!formState?.DirtyFields.includes(key);

  if (!formState) return state;

  // Cost code info error message
  const costCodeLoadError =
    !validCodeInfo(formState.SelectedCodeInfo) && formState.SelectedCodeInfo
      ? formState.SelectedCodeInfo.Description
      : "";

  // Get count of cost codes returned from the API
  const costCodeCount = validCodeInfo(formState.SelectedCodeInfo)
    ? formState.SelectedCodeInfo.CostCodes.length
    : 0;

  // Get timesheet type
  const timesheetType = formState.TimesheetTypeId
    ? findTimesheetTypeById(state.TimesheetTypes, formState.TimesheetTypeId)
    : null;

  // Validate timesheet type (this shouldn't ever trigger)
  if (!formState.TimesheetTypeId) {
    validationErrors.push({
      Selector: "#TimesheetType",
      Message: "Timesheet Type is required.",
    });
  }

  // Check if there were API errors
  if (costCodeLoadError) {
    validationErrors.push({
      Selector: "#TimesheetJobCode",
      Message: costCodeLoadError,
    });
  }

  // Validate job code
  if (validate("TimesheetJobCode") && !timesheetType?.IsLeave) {
    // Job code is required
    if (!formState.Code) {
      validationErrors.push({
        Selector: "#TimesheetJobCode",
        Message: "Project or GL Code is required.",
      });
    } else if (isNewRecord && isJobCodeBlacklisted(formState.Code)) {
      validationErrors.push({
        Selector: "#TimesheetJobCode",
        Message: "You are not allowed to use this Project or GL Code.",
      });
    }
  }

  // Validate cost code
  if (validate("CostCode") && !timesheetType?.IsLeave) {
    if (!formState.CostCode && costCodeCount > 0) {
      validationErrors.push({
        Selector: "#CostCode",
        Message: "Please select Cost Code.",
      });
    }
  }

  // Validate day/week numbers
  const wageType = state.CalendarInfo.WageType;

  const showNormalQty = timesheetType?.NormalQtyAccess === "S";
  const showOvertimeQty =
    timesheetType?.OvertimeQtyAccess === "S" && wageType !== "S";
  const showPublicHolidayQty =
    timesheetType?.PublicHolidayQtyAccess === "S" && wageType !== "S";
  const showExtraQty =
    timesheetType?.ExtraQtyAccess === "S" && wageType !== "S";

  if (formState.EntryPeriod === "day") {
    let fields = [];

    if (showNormalQty)
      fields.push({ name: "dayNormalQty", qty: formState.DayData.NormalQty });

    if (showOvertimeQty)
      fields.push({
        name: "dayOvertimeQty",
        qty: formState.DayData.OvertimeQty,
      });

    if (showPublicHolidayQty)
      fields.push({
        name: "dayPublicHolidayQty",
        qty: formState.DayData.PublicHolidayQty,
      });

    if (showExtraQty)
      fields.push({ name: "dayExtraQty", qty: formState.DayData.ExtraQty });

    const fieldsToValidate = fields.filter((field) => validate(field.name));

    validateQty(
      validationErrors,
      formState.RequiredTotalHours ?? null,
      fields,
      fieldsToValidate
    );
  } else if (timesheetType && formState.EntryPeriod === "week") {
    const days = getCalendarInfoDaysFromMonday();
    const weeklyErrors: InvalidFormItem[] = [];
    let daySucceeded = false;

    days.forEach((day) => {
      const dayKey = lowerCaseFirstChar(day);

      let fields = [];

      if (!formState.WeekData) return;

      if (showNormalQty)
        fields.push({
          name: `${dayKey}NormalQty`,
          qty: formState.WeekData[day].NormalQty,
        });

      if (showOvertimeQty)
        fields.push({
          name: `${dayKey}OvertimeQty`,
          qty: formState.WeekData[day].OvertimeQty,
        });

      if (showPublicHolidayQty)
        fields.push({
          name: `${dayKey}PublicHolidayQty`,
          qty: formState.WeekData[day].PublicHolidayQty,
        });

      if (showExtraQty)
        fields.push({
          name: `${dayKey}ExtraQty`,
          qty: formState.WeekData[day].ExtraQty,
        });

      const fieldsToValidate = fields.filter((field) => validate(field.name));

      const validationResult = validateQty(
        weeklyErrors,
        formState.RequiredTotalHours ?? null,
        fields,
        fieldsToValidate
      );

      daySucceeded = daySucceeded || validationResult;
    });

    const allErrorsRequired = weeklyErrors.every(
      (e) => e.ErrorType === "REQUIRED"
    );

    // At least one day needs to be valid otherwise, show errors
    if (!daySucceeded || (daySucceeded && !allErrorsRequired)) {
      // Combine unique messages together into single error
      validationErrors.push({
        Selector: "[data-timesheetEntryOptions] [data-validationTarget]",
        Message: uniqBy(weeklyErrors, (x) => x.Message)
          .map((x) => x.Message)
          .join("\n"),
      });
    }
  }

  return setFormState<TimesheetForm>("Timesheet", state, {
    Validation: { InvalidItems: validationErrors },
  });
};

const validNumber = (source: number) =>
  !isNaN(source) && countDecimals(source) <= 2;

/**
 * Validates a list of hourly fields
 * @param requiredTotal Sum of hours that must be set
 * @param forceSelector Override validation error selector
 * @param allFields List of fields to validate
 * @returns True if all fields are valid
 */
const validateQty = (
  validationErrors: InvalidFormItem[],
  requiredTotal: number | null,
  allFields: { name: string; qty: number | null }[],
  fieldsToValidate: { name: string; qty: number | null }[]
) => {
  if (
    !allFields ||
    !allFields.length ||
    !fieldsToValidate ||
    !fieldsToValidate.length
  )
    return true;

  // Need to use toFixed to resolve floating point
  // rounding issues that can occur
  const sum = +allFields
    .reduce((sum, field) => (sum += field.qty ? field.qty : 0), 0)
    .toFixed(10);

  if (requiredTotal && requiredTotal !== sum) {
    validationErrors.push({
      Selector: `[name="${allFields[0].name}"]`,
      Message: `Sum of all hours must total '${requiredTotal}'.`,
      ErrorType: "MATCHING_SUM",
    });

    return false;
  } else if (sum === 0) {
    validationErrors.push({
      Selector: `[name="${allFields[0].name}"]`,
      Message: "Please enter a quantity.",
      ErrorType: "REQUIRED",
    });

    return false;
  }

  // Validate individual values
  return fieldsToValidate.reduce((result, field) => {
    if (!validNumber(field.qty ?? 0)) {
      validationErrors.push({
        Selector: `[name="${field.name}"]`,
        Message: "Maximum two digits after decimal.",
        ErrorType: "INVALID_NUMBER",
      });

      return false;
    } else if (field.qty && field.qty < 0) {
      validationErrors.push({
        Selector: `[name="${field.name}"]`,
        Message: "Quantity must be non-negative.",
        ErrorType: "NEGATIVE_NUMBER",
      });

      return false;
    }

    return result;
  }, true);
};
