export interface APIError {
  /**
   * Gets or sets the code.
   * @value
   * The code.
   */
  Code: number;

  /**
   * Gets or sets the record identifier.
   * @value
   * The record identifier.
   */
  RecordIdentifier: string | null;

  /**
   * Gets or sets the location.
   * @value
   * The location.
   */
  Location: string | null;

  /**
   * Gets or sets the description.
   * @value
   * The description.
   */
  Description: string | null;

  /**
   * Gets or sets the type.
   * @value
   * The type.
   */
  Type: "E" | "V"; //E = Error, V = Validation
}

export const isAPIError = (body: any): body is APIError => {
  return !!body?.Type && !!body?.Code && typeof body?.Location != "undefined";
};
