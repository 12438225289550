import {
  CommonViewModel,
  TimesheetInfoForPeriod,
} from "../Models/CommonViewModel";
import { RootState } from "../Models/RootState";
import {
  parseCommonDate,
  formatDate,
  getCalendarInfoDaysFromMonday,
  addDays,
} from "./DateHelper";
import { Timesheet } from "../Models/Timesheet";
import { TimesheetTemplate } from "../Models/TimesheetTemplate";
import { TimesheetForm } from "../Models/TimesheetForm";
import { CalendarInfoDays, CalendarInfo } from "../Models/CalendarInfo";
import { TimesheetType } from "../Models/TimesheetType";
import { findTimesheetTemplateByTimesheetEntry } from "./TimesheetTemplateHelper";
import { findTimesheetTypeById } from "./TimesheetTypeHelper";

export const calculateDaysForPeriod = (startDate: Date) => {
  return {
    Monday: startDate,
    Tuesday: addDays(startDate, 1),
    Wednesday: addDays(startDate, 2),
    Thursday: addDays(startDate, 3),
    Friday: addDays(startDate, 4),
    Saturday: addDays(startDate, 5),
    Sunday: addDays(startDate, 6),
  };
};

export const addTimesheetInfoForPeriodToState = (
  state: RootState,
  model: TimesheetInfoForPeriod,
  extraCalendarInfo?: Partial<CalendarInfo>
): RootState => {
  return {
    ...state,
    CalendarInfo: {
      ...state.CalendarInfo,
      SelectedDate: model.SelectedDate,
      StartDate: model.StartDate,
      EndDate: model.EndDate,
      Timesheets: model.timesheets,
      TimesheetPeriodStatusCode: model.TimesheetStatusCode,
      CurrentPeriodDays: calculateDaysForPeriod(model.StartDate),
      ...extraCalendarInfo,
    },
    TimesheetTypes: model.timesheetTypes.length
      ? model.timesheetTypes
      : state.TimesheetTypes,
  };
};

export const addCommonViewModelToState = (
  state: RootState,
  model: CommonViewModel,
  extraCalendarInfo?: Partial<CalendarInfo>
): RootState => {
  // Model provides multiple ways to access TimesheetTypes :(
  const timesheetTypes: TimesheetType[] = model.timesheetTemplateinfo
    .timesheetTypes.length
    ? model.timesheetTemplateinfo.timesheetTypes
    : model.timesheetinfo.timesheetTypes;

  const employeeInfo = model.employeeInfo ?? state.CalendarInfo.EmployeeInfo;

  return {
    ...state,
    CalendarInfo: {
      ...state.CalendarInfo,
      WageType: model.WageType,
      SelectedDate: model.StartDate,
      StartDate: model.StartDate,
      EndDate: model.EndDate,
      Favourites: model.timesheetTemplateinfo.timesheetTemplates,
      Timesheets: model.timesheetinfo.timesheets,
      TimesheetPeriodStatusCode: model.timesheetinfo.TimesheetStatusCode,
      CurrentPeriodDays: calculateDaysForPeriod(model.StartDate),
      AccountName: employeeInfo.AccountName ?? "",
      EmployeeInfo: employeeInfo,
      EmployeeCode: employeeInfo.EmployeeCode,
      EmployeeDisplayName: employeeInfo.GivenNames + " " + employeeInfo.Surname,

      ...extraCalendarInfo,
    },
    TimesheetTypes: timesheetTypes,
  };
};

export const addFavouriteToState = (
  state: RootState,
  timesheetTemplate: TimesheetTemplate
): RootState => {
  return {
    ...state,
    CalendarInfo: {
      ...state.CalendarInfo,
      Favourites: [...state.CalendarInfo.Favourites, timesheetTemplate],
    },
  };
};

export const removeFavouriteFromState = (
  state: RootState,
  templateId: number
): RootState => {
  return {
    ...state,
    CalendarInfo: {
      ...state.CalendarInfo,
      Favourites: state.CalendarInfo.Favourites.filter(
        (fav) => fav.TemplateId !== templateId
      ),
    },
  };
};

export const removeTimesheetEntry = (
  state: RootState,
  timesheetId: number
): RootState => {
  return {
    ...state,
    CalendarInfo: {
      ...state.CalendarInfo,
      Timesheets: state.CalendarInfo.Timesheets.filter(
        (ts) => ts.RecordId !== timesheetId
      ),
    },
  };
};

export const timesheetFormStateFromTimesheetRecord = (
  timesheet: Timesheet
): TimesheetForm => ({
  EntryPeriod: "day",
  RecordId: timesheet.RecordId,
  EmployeeCode: timesheet.EmployeeCode,
  TimesheetTypeId: timesheet.TimesheetTypeId,
  Code: timesheet.Code,
  CostCode: timesheet.CostCode,
  Description: timesheet.Description,
  Title: timesheet.Title,
  ItemStatusCode: timesheet.ItemStatusCode,
  TimesheetStatusCode: timesheet.TimesheetStatusCode,
  ShiftType: timesheet.ShiftType ?? null,
  DayData: {
    WorkedDate: parseCommonDate(timesheet.WorkedDate ?? ""),
    NormalQty: timesheet.NormalQty,
    ExtraQty: timesheet.ExtraQty,
    OvertimeQty: timesheet.OvertimeQty,
    PublicHolidayQty: timesheet.PublicHolidayQty,
    NormalRate: timesheet.NormalRate,
  },
  Validation: {
    InvalidItems: [],
  },
  DirtyFields: [],
});

export const initialTimesheetFormState = (
  selectedDate: Date,
  calendarDates: CalendarInfoDays
): TimesheetForm => {
  const emptyDay = {
    NormalQty: null,
    ExtraQty: null,
    OvertimeQty: null,
    PublicHolidayQty: null,
    NormalRate: null,
  };

  return {
    EntryPeriod: "day",
    RecordId: 0,
    EmployeeCode: null,
    TimesheetTypeId: null,
    Code: null,
    CostCode: null,
    Description: null,
    Title: null,
    ItemStatusCode: null,
    TimesheetStatusCode: null,
    ShiftType: null,
    WeekData: {
      Monday: { ...emptyDay, WorkedDate: calendarDates.Monday },
      Tuesday: { ...emptyDay, WorkedDate: calendarDates.Tuesday },
      Wednesday: { ...emptyDay, WorkedDate: calendarDates.Wednesday },
      Thursday: { ...emptyDay, WorkedDate: calendarDates.Thursday },
      Friday: { ...emptyDay, WorkedDate: calendarDates.Friday },
      Saturday: { ...emptyDay, WorkedDate: calendarDates.Saturday },
      Sunday: { ...emptyDay, WorkedDate: calendarDates.Sunday },
    },
    DayData: { ...emptyDay, WorkedDate: selectedDate },
    Validation: {
      InvalidItems: [],
    },
    DirtyFields: [],
  };
};

/**
 * Helper that makes updating form state easier
 * @param state Current RootState
 * @param newFormState Changes to form state
 */
export const setFormState = <T>(
  key: keyof RootState["Forms"],
  state: RootState,
  newFormState: T | Partial<T>
): RootState => {
  if (!state.Forms[key]) return state;

  return {
    ...state,
    Forms: {
      ...state.Forms,
      [key]: {
        ...state.Forms[key],
        ...newFormState,
      },
    },
  };
};

export const findFavouriteForTimesheetEntry = (
  state: RootState,
  timesheet: Timesheet | TimesheetForm
) => {
  return findTimesheetTemplateByTimesheetEntry(
    state.CalendarInfo.Favourites,
    timesheet
  );
};

export const createTimesheetRecordFromCurrentFormState = (
  state: RootState
): Timesheet | Timesheet[] => {
  const formState = state.Forms.Timesheet;

  if (!formState?.TimesheetTypeId) {
    throw new Error("Invalid timesheet type ID");
  }

  const baseTimesheet: Timesheet = {
    RecordId: formState.RecordId,
    EmployeeCode: formState.EmployeeCode,
    TimesheetTypeId: formState.TimesheetTypeId,
    Code: formState.Code,
    CostCode: formState.CostCode,
    Description: formState.Description,
    Title: formState.Title,
    ItemStatusCode: "E",
    TimesheetStatusCode: "E",
    WorkedDate: "",
    NormalQty: 0,
    NormalRate: null,
    ExtraQty: null,
    OvertimeQty: null,
    PublicHolidayQty: null,
    ShiftType: formState.ShiftType,
  };

  if (formState.EntryPeriod === "day") {
    return {
      ...baseTimesheet,
      WorkedDate: formatDate(formState.DayData.WorkedDate),
      NormalQty: formState.DayData.NormalQty ?? 0,
      NormalRate: formState.DayData.NormalRate,
      ExtraQty: formState.DayData.ExtraQty,
      OvertimeQty: formState.DayData.OvertimeQty,
      PublicHolidayQty: formState.DayData.PublicHolidayQty,
    };
  } else if (formState.EntryPeriod === "week") {
    const timesheets: Timesheet[] = [];
    const weekData = formState.WeekData;

    if (!weekData) throw new Error("Week data undefined");

    const days = getCalendarInfoDaysFromMonday();
    const timesheetType = findTimesheetTypeById(
      state.TimesheetTypes,
      formState.TimesheetTypeId
    );

    days.forEach((day) => {
      const dayData = weekData[day];

      // Check if data has been changed from default
      if (
        timesheetType?.NormalQtyAccess !== "H" &&
        timesheetType?.NormalRateAccess !== "H" &&
        dayData.NormalQty == 0
      ) {
        return;
      }

      // Check if data has been set for this day
      else if (
        !dayData.NormalQty &&
        !dayData.ExtraQty &&
        !dayData.OvertimeQty &&
        !dayData.PublicHolidayQty &&
        !dayData.NormalRate
      ) {
        return;
      }

      timesheets.push({
        ...baseTimesheet,
        WorkedDate: formatDate(dayData.WorkedDate),
        NormalQty: dayData.NormalQty ?? 0,
        NormalRate: dayData.NormalRate,
        ExtraQty: dayData.ExtraQty,
        OvertimeQty: dayData.OvertimeQty,
        PublicHolidayQty: dayData.PublicHolidayQty,
      });
    });

    return timesheets;
  } else {
    throw new Error("Unknown entry period");
  }
};
