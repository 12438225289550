import { CommonViewModel } from "../Models/CommonViewModel";
import { ImpersonationInfo } from "../Models/ImpersonationInfo";
import { EMPLOYEE_ROLE_PAYROLL, SessionInfo } from "../Models/SessionInfo";
import { TeamMemberEmployee } from "../Models/TeamMemberEmployee";
import { TimesheetApi } from "../Services/TimesheetApi";
import { showLoader } from "./ApiHelper";
import { parseCommonDate } from "./DateHelper";

export type PreloadResult = {
  commonViewModel: CommonViewModel;
  sessionInfo: SessionInfo;
  sessionEmployeeInfo: TeamMemberEmployee;
  impersonationInfo?: ImpersonationInfo;
  isMobile: boolean;
};

function isMobile() {
  return window.innerWidth <= 600 && window.innerHeight <= 1000;
}

function parseQuery(queryString: string) {
  const query: Record<string, string> = {};
  const pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");

  if (!queryString) return query;

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }

  return query;
}

/**
 * Returns a payload that is the same as what the old
 * .NET MVC app would inject into the page on load.
 * But instead calls the timesheet APIs directly.
 *
 * At some point this should be reworked to use models
 * that better fit the timesheet APIs directly.
 */
export async function preloadAppData(
  api: TimesheetApi
): Promise<PreloadResult> {
  showLoader();

  const queryParams = parseQuery(window.location.search);

  let period = new Date();

  if (queryParams.Date) period = parseCommonDate(queryParams.Date);

  const sessionInfo = await api.getSessionInfo();

  // This can happen if the token has expired or
  // when using IE 11 on first visit.
  if (sessionInfo == null) {
    throw {
      name: "invalid_session",
      message: "Failed to retrieve session info",
    };
  }

  const sessionEmployeeInfo = await api.getEmployeeInfo(
    sessionInfo.AccountName
  );

  let impersonationInfo: ImpersonationInfo | undefined = undefined;
  let selectedEmployeeInfo: TeamMemberEmployee;

  if (
    queryParams.AccountName &&
    sessionEmployeeInfo.AccessType === EMPLOYEE_ROLE_PAYROLL
  ) {
    selectedEmployeeInfo = await api.getEmployeeInfo(queryParams.AccountName);

    impersonationInfo = {
      AccountName: selectedEmployeeInfo.AccountName,
      EmployeeCode: selectedEmployeeInfo.EmployeeCode,
      EmployeeDisplayName:
        selectedEmployeeInfo.GivenNames + selectedEmployeeInfo.Surname,
    };
  } else {
    selectedEmployeeInfo = sessionEmployeeInfo;
  }

  const commonViewModel = await api.getSelectedEmployeeTimesheetData(
    selectedEmployeeInfo,
    selectedEmployeeInfo.EmployeeCode,
    period,
    {
      useEmployeeSettings: !impersonationInfo,
    }
  );

  return {
    commonViewModel,
    sessionInfo: {
      ...sessionInfo,
      EmployeeRole: sessionEmployeeInfo.AccessType,
      DefaultTSCostCode: selectedEmployeeInfo.DefaultTSCostCode,
      DefaultTSCurrentWeek: selectedEmployeeInfo.DefaultTSCurrentWeek,
    },
    sessionEmployeeInfo: sessionEmployeeInfo,
    impersonationInfo,
    isMobile: isMobile(),
  };
}
