import appInsights from "../ApplicationInsights";
import { hideLoader, showLoader } from "../Helpers/ApiHelper";
import { AuthModule } from "../Helpers/AuthModule";
import {
  addDays,
  formatDate,
  getEndOfWeek,
  getStartOfWeek,
  parseCommonDate,
  subtractDays,
} from "../Helpers/DateHelper";
import { APIError, isAPIError } from "../Models/APIError";
import {
  CommonViewModel,
  TimesheetInfo,
  TimesheetInfoForPeriod,
  TimesheetTemplateInfo,
} from "../Models/CommonViewModel";
import { SessionInfoBase } from "../Models/SessionInfo";
import {
  NewTeamMemberEmployee,
  TeamMemberEmployee,
  TeamMemberEmployeeForList,
} from "../Models/TeamMemberEmployee";
import { Timesheet } from "../Models/Timesheet";
import { CodeInfo } from "../Models/TimesheetForm";
import { TimesheetPeriodStatus } from "../Models/TimesheetPeriodStatus";
import { TimesheetTemplate } from "../Models/TimesheetTemplate";
import { TimesheetType } from "../Models/TimesheetType";
import { WorkCode } from "../Models/WorkCode";

export type ApiStatusResult = {
  Status: string;
  Successful: boolean;
  Failed: boolean;
  Error?: APIError;
};

export type CancellationToken = {
  cancel: () => void;
};

export type ApiOptions = {
  showLoader?: boolean;
  cancellationToken?: CancellationToken;
};

export type ApiResponse<T> =
  | {
      ok: false;
      body?: T;
      status: number;
      statusText: string;
    }
  | {
      ok: true;
      body: T;
      status: number;
      statusText: string;
    };

export function createCancellationToken() {
  return {
    cancel: () => {}, // no-op, this gets replaced later on see this.doApiCall(...)
  };
}

export class TimesheetApi {
  private authModule: AuthModule;
  private baseUrl: string = TIMESHEET_API_BASE_URL;

  constructor(authModule: AuthModule) {
    this.authModule = authModule;
  }

  async getSessionInfo(): Promise<SessionInfoBase | null> {
    return this.authModule.getSessionInfo();
  }

  async getTimesheetTypes(): Promise<TimesheetType[]> {
    const response = await this.callApi<TimesheetType[]>(
      "GET",
      "/TimesheetType/GetList"
    );

    if (!response.ok) {
      throw new Error(
        "Failed to retrieve timesheet types " + response.statusText
      );
    }

    return response.body!;
  }

  async getEmployeeInfo(
    accountName: string,
    options?: ApiOptions
  ): Promise<TeamMemberEmployee> {
    const response = await this.callApi<TeamMemberEmployee>(
      "GET",
      "/TeamMember/GetTeamMemberEmployee",
      { accountName },
      options
    );

    if (!response.ok) {
      throw new Error(
        "Failed to retrieve employee info " + response.statusText
      );
    }

    return response.body;
  }

  async getTimesheetTemplateInfo(
    employeeCode: string,
    options?: ApiOptions
  ): Promise<TimesheetTemplateInfo> {
    const response = await this.callApi<TimesheetTemplateInfo>(
      "GET",
      "/TimesheetTemplate/Get",
      { employeeCode },
      options
    );

    if (!response.ok) {
      throw new Error(
        "Failed to retrieve timesheet template info " + response.statusText
      );
    }

    return response.body;
  }

  async getTimesheetEntryById(
    recordId: number,
    options?: ApiOptions
  ): Promise<Timesheet> {
    const response = await this.callApi<Timesheet>(
      "GET",
      "/Timesheet/GetByRecordId",
      {
        recordId,
      },
      options
    );

    if (!response.ok) {
      throw new Error(
        `Failed to retrieve timesheet (${recordId}): ` + response.statusText
      );
    }

    return response.body;
  }

  async getTimesheetInfo(
    employeeCode: string,
    dateFrom: Date,
    dateTo: Date,
    options?: ApiOptions
  ): Promise<TimesheetInfoForPeriod> {
    const response = await this.callApi<TimesheetInfo>(
      "GET",
      "/Timesheet/Get",
      {
        employeeCode,
        dateFrom: dateFrom.toISOString(),
        dateTo: dateTo.toISOString(),
      },
      options
    );

    if (!response.ok) {
      throw new Error(
        "Failed to retrieve timesheet info " + response.statusText
      );
    }

    return {
      ...response.body,
      SelectedDate: dateFrom,
      StartDate: dateFrom,
      EndDate: dateTo,
    };
  }

  showSelectedWeekTimesheet(
    selectedDate: Date,
    employeeCode: string,
    options?: ApiOptions
  ) {
    const curr = new Date(selectedDate);
    const firstDay = getStartOfWeek(curr);
    const lastDay = getEndOfWeek(curr);

    return this.getTimesheetInfo(employeeCode, firstDay, lastDay, options);
  }

  showPreviousTimesheet(
    currentDate: Date,
    employeeCode: string,
    options?: ApiOptions
  ) {
    const lastWeek = subtractDays(currentDate, 7);
    const firstDay = getStartOfWeek(lastWeek);
    const lastDay = getEndOfWeek(lastWeek);

    return this.getTimesheetInfo(employeeCode, firstDay, lastDay, options);
  }

  showNextTimesheet(
    currentDate: Date,
    employeeCode: string,
    options?: ApiOptions
  ) {
    const nextWeek = addDays(currentDate, 7);
    const firstDay = getStartOfWeek(nextWeek);
    const lastDay = getEndOfWeek(nextWeek);

    return this.getTimesheetInfo(employeeCode, firstDay, lastDay, options);
  }

  async saveTimesheetTemplateInfo(
    timesheetTemplateInfo: TimesheetTemplate,
    options?: ApiOptions
  ): Promise<ApiStatusResult & { TemplateId?: number }> {
    const response = await this.callApi<TimesheetTemplate>(
      "POST",
      "/TimesheetTemplate/Update",
      timesheetTemplateInfo,
      options
    );

    return {
      Successful: response.ok,
      Failed: !response.ok,
      Status: response.statusText,
      TemplateId: response.ok ? response.body.TemplateId : undefined,
    };
  }

  async deleteTimesheetTemplateInfo(
    recordId: string,
    options?: ApiOptions
  ): Promise<ApiStatusResult> {
    const response = await this.callApi<true>(
      "DELETE",
      "/TimesheetTemplate/Delete",
      { templateId: recordId },
      options
    );

    return {
      Successful: response.ok && response.body,
      Failed: !response.ok || !response.body,
      Status: response.statusText,
    };
  }

  async saveTimesheet(
    timesheet: Timesheet,
    options?: ApiOptions
  ): Promise<ApiStatusResult & { RecordId?: number }> {
    const response = await this.callApi<Timesheet>(
      "POST",
      "/Timesheet/Update",
      { timesheet },
      options
    );

    return {
      Successful: response.ok,
      Failed: !response.ok,
      Status: response.statusText,
      RecordId: response.ok ? response.body.RecordId : undefined,
      Error: isAPIError(response.body) ? response.body : undefined,
    };
  }

  async saveTimesheets(
    timesheets: Timesheet[],
    options?: ApiOptions
  ): Promise<ApiStatusResult & { TimesheetInfo?: TimesheetInfo }> {
    const response = await this.callApi<TimesheetInfo>(
      "POST",
      "/Timesheet/UpdateList",
      { timesheets },
      options
    );

    return {
      Successful: response.ok,
      Failed: !response.ok,
      Status: response.statusText,
      TimesheetInfo: response.ok ? response.body : undefined,
      Error: isAPIError(response.body) ? response.body : undefined,
    };
  }

  saveTimesheetRecordForDay(timesheet: Timesheet, options?: ApiOptions) {
    return this.saveTimesheet(timesheet, options);
  }

  saveTimesheetRecordForWeek(timesheets: Timesheet[], options?: ApiOptions) {
    return this.saveTimesheets(timesheets, options);
  }

  async deleteTimesheetRecord(
    recordId: number,
    options?: ApiOptions
  ): Promise<ApiStatusResult> {
    const response = await this.callApi<true>(
      "DELETE",
      "/Timesheet/Delete",
      { recordId },
      options
    );

    return {
      Successful: response.ok && response.body,
      Failed: !response.ok || !response.body,
      Status: response.statusText,
    };
  }

  async copyTimesheetToNextDay(
    recordId: number,
    options?: ApiOptions
  ): Promise<ApiStatusResult> {
    const timesheet = await this.getTimesheetEntryById(recordId, options);

    timesheet.RecordId = 0;

    if (!timesheet.WorkedDate)
      throw new Error("Cannot copy timesheet without WorkedDate");

    timesheet.WorkedDate = formatDate(
      addDays(parseCommonDate(timesheet.WorkedDate), 1)
    );

    return this.saveTimesheet(timesheet, options);
  }

  async copyTimesheetToWeek(
    recordId: number,
    options?: ApiOptions
  ): Promise<ApiStatusResult> {
    const sourceTimesheet = await this.getTimesheetEntryById(recordId, options);

    if (!sourceTimesheet.WorkedDate)
      throw new Error("Cannot copy timesheet without WorkedDate");

    const sourceDate = parseCommonDate(sourceTimesheet.WorkedDate);

    // Returns how many copies to create based on day of week.
    // E.g. Copying a monday entry will copy it to the rest of
    //      the work week (i.e. from tuesday to friday).
    // E.g. Copying a friday entry will copy it to just saturday
    //      and sunday.
    const copies = [
      /* Sun */ 0, /* Mon */ 4, /* Tue */ 3, /* Wed */ 2, /* Thu */ 1,
      /* Fri */ 2, /* Sat */ 1,
    ];

    const timesheets: Timesheet[] = [];

    for (let index = 1; index <= copies[sourceDate.getDay()]; index++) {
      timesheets.push({
        ...sourceTimesheet,
        RecordId: 0,
        WorkedDate: formatDate(addDays(sourceDate, index)),
      });
    }

    return this.saveTimesheets(timesheets, options);
  }
  async getSelectedEmployeeTimesheetDataByAccountName(
    accountName: string,
    employeeCode: string,
    period?: Date,
    options?: ApiOptions & { useEmployeeSettings?: boolean }
  ): Promise<CommonViewModel> {
    const employeeInfo = await this.getEmployeeInfo(accountName, options);
    return this.getSelectedEmployeeTimesheetData(
      employeeInfo,
      employeeCode,
      period,
      options
    );
  }

  async getSelectedEmployeeTimesheetData(
    employeeInfo: TeamMemberEmployee,
    employeeCode: string,
    period?: Date,
    options?: ApiOptions & { useEmployeeSettings?: boolean }
  ): Promise<CommonViewModel> {
    const today = new Date();
    const thisWeekStart = getStartOfWeek(today);
    const thisWeekEnd = getEndOfWeek(today);
    period = period ?? today;

    const useEmployeeSettings = options?.useEmployeeSettings ?? true;
    const startDate = getStartOfWeek(period);
    const endDate = getEndOfWeek(period);

    // IF DefaultTSCurrentWeek setting is false
    //   AND if today is Sunday/Monday/Tuesday
    //   AND if period is in this week
    // THEN show previous week timesheet
    if (
      useEmployeeSettings &&
      !employeeInfo.DefaultTSCurrentWeek &&
      period.getDay() <= 2 &&
      period >= thisWeekStart &&
      period <= thisWeekEnd
    ) {
      startDate.setDate(startDate.getDate() - 7);
      endDate.setDate(endDate.getDate() - 7);
    }

    const templateInfo = await this.getTimesheetTemplateInfo(
      employeeCode,
      options
    );
    const timesheetInfo = await this.getTimesheetInfo(
      employeeCode,
      startDate,
      endDate,
      options
    );

    appInsights.trackEvent({
      name: "getSelectedEmployeeTimesheetData",
      properties: {
        employeeCode,
        today: today.toDateString(),
        period: period.toDateString(),
        startDate,
        endDate,
        options,
        timesheetInfo,
      },
    });

    return {
      StartDate: startDate,
      EndDate: endDate,
      WageType: employeeInfo.WageType,
      employeeInfo: employeeInfo,
      timesheetTemplateinfo: templateInfo,
      timesheetinfo: timesheetInfo,
    };
  }

  async getEmployeeListForAutoComplete(
    searchTerm: string,
    options?: ApiOptions
  ): Promise<TeamMemberEmployeeForList[]> {
    const response = await this.callApi<TeamMemberEmployeeForList[]>(
      "GET",
      "/TeamMember/Search",
      {
        term: searchTerm,
      },
      options
    );

    if (!response.ok) {
      throw new Error(
        "Failed to getEmployeeListForAutoComplete " + response.statusText
      );
    }

    return response.body;
  }

  async getCostCodeInfo(
    code: string,
    timesheetTypeId: number,
    claimName: string,
    options?: ApiOptions
  ): Promise<CodeInfo> {
    const response = await this.callApi<WorkCode | APIError>(
      "GET",
      "/WorkCode/GetValidWorkCode",
      {
        code,
        timesheetTypeId,
        claimName,
      },
      options
    );

    if (!response.body) {
      throw new Error(
        "Failed to retrieve cost code info " + response.statusText
      );
    }

    return response.body;
  }

  async updateEmployeeInfo(
    employee: NewTeamMemberEmployee,
    options?: ApiOptions
  ): Promise<ApiStatusResult> {
    const response = await this.callApi<TimesheetInfo>(
      "POST",
      "/TeamMember/UpdateTeamMemberByEmployee",
      { teamMemberEmployee: employee, accountName: employee.AccountName },
      options
    );

    return {
      Successful: response.ok,
      Failed: !response.ok,
      Status: response.statusText,
    };
  }

  async saveTimesheetPeriodStatus(
    periodStatus: TimesheetPeriodStatus,
    options?: ApiOptions
  ): Promise<ApiStatusResult> {
    const response = await this.callApi<TimesheetInfo>(
      "POST",
      "/TimesheetPeriodStatus/Update",
      { periodStatus },
      options
    );

    return {
      Successful: response.ok,
      Failed: !response.ok,
      Status: response.statusText,
    };
  }

  private async callApi<TRes>(
    method: "GET" | "POST" | "PUT" | "DELETE",
    endpoint: string,
    params?: Record<string, any>,
    options?: ApiOptions
  ): Promise<ApiResponse<TRes>> {
    const cancelResponse = { isCancelled: true, textStatus: "abort" };

    let cancelled = false;

    if (options?.cancellationToken) {
      options.cancellationToken.cancel = () => (cancelled = true);
    }

    if (options?.showLoader) showLoader();

    try {
      if (cancelled) return Promise.reject(cancelResponse);

      const accessToken = await this.authModule.getTimesheetApiTokenPopup();

      if (!accessToken) throw new Error("Access token is null");

      let url = this.baseUrl + endpoint;

      if (method == "GET" && params) {
        url += "?" + new URLSearchParams(params);
      }

      if (cancelled) return Promise.reject(cancelResponse);

      const response = await fetch(url, {
        method,
        body: method != "GET" ? JSON.stringify(params) : undefined,
        credentials: "omit",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      });

      const isJsonContentType = response.headers
        .get("content-type")
        ?.includes("application/json");

      if (cancelled) return Promise.reject(cancelResponse);

      if (options?.showLoader) hideLoader();

      if (response.ok) {
        return {
          ok: true,
          body: (await response.json()) as TRes,
          status: response.status,
          statusText: response.statusText,
        };
      } else {
        return {
          ok: false,
          body: isJsonContentType
            ? ((await response.json()) as TRes)
            : undefined,
          status: response.status,
          statusText: response.statusText,
        };
      }
    } catch (e) {
      if (options?.showLoader) hideLoader();
      appInsights.trackException({
        error: e as any,
      });
      return {
        ok: false,
        status: -1,
        statusText: (e as Error)?.message ?? "Unknown error",
      };
    }
  }
}
