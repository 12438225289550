import { TimesheetType } from "../Models/TimesheetType";

export type IconInfo = {
  imagePath: string;
  label: string;
  backgroundClass: string;
};

export function getTimesheetIconInfo(timesheetType: TimesheetType): IconInfo {
  const typeDescription = timesheetType.Description?.toLowerCase() ?? "";

  let imagePath = "";
  let dataOriginalTitle = "";
  let imageSpanClass = "";

  if (typeDescription == "hours") {
    imagePath = "/img/hours.png";
    dataOriginalTitle = "Timesheet Type: Hours";
    imageSpanClass = "bg-gray";
  } else if (typeDescription == "living away") {
    imagePath = "/img/living-away-from-house.png";
    dataOriginalTitle = "Timesheet Type: Living Away From Home";
    imageSpanClass = "bg-blue";
  } else if (typeDescription == "km's travelled") {
    imagePath = "/img/km-travlled.png";
    dataOriginalTitle = "Timesheet Type: Km's Travelled";
    imageSpanClass = "bg-blue";
  } else if (typeDescription == "on call") {
    imagePath = "/img/on-call.png";
    dataOriginalTitle = "Timesheet Type: On call";
    imageSpanClass = "bg-blue";
  } else if (typeDescription == "site allowance") {
    imagePath = "/img/site-allowance.png";
    dataOriginalTitle = "Timesheet Type: Site Allowance";
    imageSpanClass = "bg-blue";
  } else if (timesheetType.IsLeave) {
    imagePath = "/img/leave.png";
    dataOriginalTitle = "Timesheet Type: " + typeDescription;
    imageSpanClass = "bg-greenyellow";
  } else if (typeDescription == "shift allowance") {
    imagePath = "/img/shift-allowance.png";
    dataOriginalTitle = "Timesheet Type: Shift Allowance";
    imageSpanClass = "bg-blue";
  } else if (typeDescription == "salesforce") {
    imagePath = "/img/external.png";
    dataOriginalTitle = "Timesheet Type: Salesforce";
    imageSpanClass = "bg-external";
  } else if (typeDescription == "external") {
    imagePath = "/img/external.png";
    dataOriginalTitle = "Timesheet Type: External";
    imageSpanClass = "bg-external";
  } else {
    imagePath = "/img/hours.png";
    dataOriginalTitle = "Timesheet Type: " + typeDescription;
    imageSpanClass = "bg-gray";
  }

  return {
    imagePath: imagePath,
    label: dataOriginalTitle,
    backgroundClass: imageSpanClass,
  };
}
