import "./ThirdParty";
import { load as loadApp } from "./App";
import {
  hideLoader,
  hideLoginScreen,
  showFatalError,
  showLoader,
  showLoginScreen,
} from "./Helpers/ApiHelper";
import { AuthModule } from "./Helpers/AuthModule";
import initVDomPlugin from "./Helpers/VDom";
import appInsights from "./ApplicationInsights";

declare global {
  var IS_DEV: boolean;
  var AAD_CLIENT_ID: string;
  var AAD_AUTHORITY: string;
  var TIMESHEET_API_BASE_URL: string;
  var APP_INSIGHTS_KEY: string;
}

initVDomPlugin();

const authModule = new AuthModule();

const handleSignIn = async () => {
  hideLoginScreen();
  showLoader();

  try {
    const accountInfo = await authModule.login();

    // If account info is return we can load the app straight away
    // otherwise we may need to wait for the redirect to return.
    if (accountInfo) {
      loadApp(authModule);
    } else {
      // If redirect fails to trigger for some reason show an error message.
      window.setTimeout(() => {
        console.error("Login redirect failed to trigger.");
        toastr.error("Login redirect failed.");
        hideLoader();
        showLoginScreen();
      }, 10000);
    }
  } catch (e) {
    appInsights.trackException({
      exception: e as any,
    });

    toastr.error("Login failed, please try again.");
    hideLoader();
    showLoginScreen();
    return;
  }
};

const handlePageLoad = async () => {
  try {
    const accountInfo = await authModule.loadAuthModule();

    $("body").removeClass("is-preloading");

    if (accountInfo) {
      console.log("AccountInfo found...", accountInfo);

      // We already have a logged in account attempt to load app
      loadApp(authModule);
    } else {
      // Show login screen
      showLoginScreen();
    }
  } catch (e) {
    console.log("Failed to initialise app", e);
    hideLoader();
    showFatalError();
  }
};

$(document).on("click", "[data-sign-in]", (e) => {
  e.preventDefault();
  handleSignIn();
});

$(document).on("click", "[data-sign-out]", (e) => {
  e.preventDefault();
  authModule.logout();
});

window.addEventListener("load", () => handlePageLoad());
