import { Timesheet } from "../Models/Timesheet";
import { TimesheetType } from "../Models/TimesheetType";
import { calculateTotalHoursByType } from "../Helpers/TimesheetTypeHelper";
import { formatTotal } from "../Helpers/StringHelpers";

export function render(
  wageType: string,
  timesheetTypes: TimesheetType[],
  timesheets: Timesheet[],
  isMobile: boolean
) {
  const hours = calculateTotalHoursByType(timesheetTypes, timesheets);

  if (wageType == "S") {
    $("#liExtraTime").hide();
    $("#liOverTime").hide();
    $("#liPublicHoliday").hide();
    $("#dvDayExtra").hide();
    $("#dvDayOvertime").hide();
    $("#dvDayPublicHoliday").hide(); //day view
    $("#trExtraTime").hide();
    $("#trOvertime").hide();
    $("#trPublicHoliday").hide(); //week view

    if (isMobile) {
      $("#ulHeading").attr("style", "text-align:center;");
    }
  } else {
    $("#liExtraTime").show();
    $("#liOverTime").show();
    $("#liPublicHoliday").show();
    $("#dvDayExtra").show();
    $("#dvDayOvertime").show();
    $("#dvDayPublicHoliday").show();
    $("#trExtraTime").show();
    $("#trOvertime").show();
    $("#trPublicHoliday").show();

    if (isMobile) {
      $("#ulHeading").attr("style", "");
    }
  }

  //show weekly normal, extra, overtime & public holiday hours
  $("#lblWeeklyNormalHours").text(formatTotal(hours.normal) + " hrs").attr('aria-description', "Weekly Normal Hours");
  $("#lblWeeklyExtraHours").text(formatTotal(hours.extra) + " hrs").attr('aria-description', "Weekly Extra Hours");
  $("#lblWeeklyOvertimeHours").text(formatTotal(hours.overtime) + " hrs").attr('aria-description', "Weekly Overtime Hours");
  $("#lblWeeklyPublicHolidayHours").text(formatTotal(hours.publicHoliday) + " hrs").attr('aria-description', "Weekly Public Holiday Hours");
  $("#lblWeeklyLeaves").text(formatTotal(hours.leave) + " hrs").attr('aria-description', "Weekly Leave Hours");
}
