import { TimesheetPeriodStatusCode } from "../Models/CommonViewModel";
import { EMPLOYEE_ROLE_PAYROLL } from "../Models/SessionInfo";
import { html } from "../Helpers/TemplateTags";

export function render(
  timesheetStatus: TimesheetPeriodStatusCode,
  userRole: string
) {
  const $container = $(".timesheet-period");
  const $label = $container.find(".timesheet-period__label");
  const $icon = $container.find(".timesheet-period__icon > i");

  if (!timesheetStatus) {
    console.error("Timesheet status is not defined");
    return;
  }

  if (timesheetStatus == "P") {
    /**
     * Timesheet status = Processed
     * ----------------------------
     * This means that the timesheet has been part of a pay run.
     * Timesheet cannot be changed by anyone and is completely locked.
     */

    replaceClass($icon, "fa-", "check");
    $label.text("Processed");

    $container.find(".timesheet-period__status-button").remove();
  } else if (timesheetStatus == "C") {
    /**
     * Timesheet status = Closed
     * ----------------------------
     * Timesheet cannot be changed by anyone, but payroll users
     * can change timesheet period status back to "Open".
     */

    replaceClass($icon, "fa-", "lock");
    $label.text("Closed");

    // Show payroll controls
    if (userRole === EMPLOYEE_ROLE_PAYROLL) {
      const $newButton = $(html`
        <button
          class="timesheet-period__status-button"
          data-updateCurrentTimesheetStatus="E"
        >
          <i class="fa fa-unlock-alt"></i>
          Reopen Period
        </button>
      `);

      $container.find(".timesheet-period__status-button").remove();
      $container.append($newButton);
    }
  } else {
    /**
     * Timesheet status = Entering/Open
     * ----------------------------
     * Normal default state. Users can add/edit/remove time entries.
     */

    replaceClass($icon, "fa-", null);
    $label.text("Open");

    // Show payroll controls
    if (userRole === EMPLOYEE_ROLE_PAYROLL) {
      const $newButton = $(html`
        <button
          class="timesheet-period__status-button"
          data-updateCurrentTimesheetStatus="C"
        >
          <i class="fa fa-lock"></i>
          Close Period
        </button>
      `);

      $container.find(".timesheet-period__status-button").remove();
      $container.append($newButton);
    }
  }

  replaceClass($container, "is-status-", timesheetStatus.toLowerCase());
  replaceClass($container, "is-role-", userRole.toLowerCase());
}

/**
 * Replace existing fa-* classes with new icon class
 * @param $el Target element
 * @param prefix Prefixes classes to replace
 * @param append New  class to append
 */
const replaceClass = (
  $el: JQuery<HTMLElement>,
  prefix: string,
  append: string | null
) => {
  $el.attr("class", (_, c) =>
    c
      ? c.replace(new RegExp(`(^|\\s)${prefix}\\S+`, "g"), "") +
        (append ? ` ${prefix}${append}` : "")
      : ""
  );
};
