export const EMPLOYEE_ROLE_EMPLOYEE = "G";
export const EMPLOYEE_ROLE_PAYROLL = "P";

export interface SessionInfoBase {
  EmployeeName: string;
  EmployeeCode: string;
  AccountName: string;
}

export interface SessionInfo extends SessionInfoBase {
  EmployeeRole: typeof EMPLOYEE_ROLE_EMPLOYEE | typeof EMPLOYEE_ROLE_PAYROLL;
  DefaultTSCurrentWeek: boolean | null;
  DefaultTSCostCode: string | null;
}
