import { Store } from "../Helpers/Store";
import { RootState } from "../Models/RootState";
import { NewTeamMemberEmployee } from "../Models/TeamMemberEmployee";
import { callStatusEndpoint } from "../Helpers/ApiHelper";
import { TimesheetApi } from "./TimesheetApi";

export class ProfileService {
  private modalEl: JQuery<HTMLElement>;

  constructor(private store: Store<RootState>, private api: TimesheetApi) {
    this.modalEl = $("#ProfilePopup");
    this.modalEl.on("hidden.bs.modal", () => this.resetForm());
  }

  private resetForm() {
    this.store.setState((state) => ({
      ...state,
      Forms: {
        ...state.Forms,
        EditProfile: {
          EmployeeCode: state.SessionInfo.EmployeeCode,
          EmployeeName: state.SessionInfo.EmployeeName,
          DefaultTSCostCode: state.SessionInfo.DefaultTSCostCode ?? "",
          DefaultTSCurrentWeek: state.SessionInfo.DefaultTSCurrentWeek ?? false,
          Validation: { InvalidItems: [] },
        },
      },
    }));
  }

  startEditingProfile() {
    this.resetForm();
    $("#ProfilePopup").modal("show");
  }

  cancelEditingProfile() {
    $("#ProfilePopup").modal("hide");
  }

  async finishEditingProfile() {
    const state = this.store.getStateOrThrow();
    const formState = state.Forms.EditProfile;
    const employeeInfo = state.EmployeeInfo;

    if (!formState || !employeeInfo) {
      toastr.error("Failed to access pre-requisite data.");
      return;
    }

    if (formState.Validation.InvalidItems.length > 0) {
      toastr.error("The form has validation errors.");
      return;
    }

    const employee: NewTeamMemberEmployee = {
      // Form variables
      DefaultTSCostCode: formState.DefaultTSCostCode,
      DefaultTSCurrentWeek: formState.DefaultTSCurrentWeek,

      // Session variables
      AccountName: employeeInfo.AccountName,
      EmployeeCode: formState.EmployeeCode,
      GivenNames: employeeInfo.GivenNames,
      Surname: employeeInfo.Surname,
      BusinessUnitCode: employeeInfo.BusinessUnitCode,
      AccessType: employeeInfo.AccessType,
      WageType: employeeInfo.WageType,
    };

    const success = await callStatusEndpoint(() =>
      this.api.updateEmployeeInfo(employee, { showLoader: true })
    );

    if (!success) return;

    toastr.success("Profile updated");

    // Update store
    this.store.setState((state) => {
      if (!state.EmployeeInfo) return state;

      return {
        ...state,
        SessionInfo: {
          ...state.SessionInfo,
          DefaultTSCostCode: formState.DefaultTSCostCode,
          DefaultTSCurrentWeek: formState.DefaultTSCurrentWeek,
        },
      };
    });
  }
}
