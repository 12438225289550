export function html(strings: TemplateStringsArray, ...values: any[]) {
  if (!strings) return "";

  let str = "";

  strings.forEach(
    (string, i) => (str += `${string}${values ? values[i] ?? "" : ""}`)
  );

  // Strips whitespace from between HTML tags
  return str.replace(
    /(<(pre|script|style|textarea)[^]+?<\/\2)|(^|>)\s+|\s+(?=<|$)/g,
    "$1$3"
  );
}
