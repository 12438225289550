import { Store } from "../../../Helpers/Store";
import { RootState } from "../../../Models/RootState";
import { setFormState } from "../../../Helpers/StateHelper";
import { TimesheetForm } from "../../../Models/TimesheetForm";
import { findTimesheetTypeById } from "../../../Helpers/TimesheetTypeHelper";

/**
 * Resets specific form values when Timesheet Type changes
 * @param store
 */
export const createMiddleware = (store: Store<RootState>) => {
  store.registerMiddleware((prevState, state, next) => {
    const formState = state.Forms.Timesheet;

    if (!formState) return next(state);

    // Check if TimesheetTypeId has been set, and has changed
    if (
      !formState.TimesheetTypeId ||
      prevState?.Forms.Timesheet?.TimesheetTypeId === formState.TimesheetTypeId
    )
      return next(state);

    // Don't reset items on edit
    if (!prevState?.Forms.Timesheet && formState.RecordId) return next(state);

    const timesheetType = findTimesheetTypeById(
      state.TimesheetTypes,
      formState.TimesheetTypeId
    );

    const emptyDay = {
      NormalQty: timesheetType?.NormalQtyDefault ?? null,
      ExtraQty: null,
      OvertimeQty: null,
      PublicHolidayQty: null,
      NormalRate: timesheetType?.NormalRateDefault ?? null,
    };

    let weekData = undefined;

    if (formState.WeekData) {
      weekData = {
        Monday: { ...formState.WeekData.Monday, ...emptyDay },
        Tuesday: { ...formState.WeekData.Tuesday, ...emptyDay },
        Wednesday: { ...formState.WeekData.Wednesday, ...emptyDay },
        Thursday: { ...formState.WeekData.Thursday, ...emptyDay },
        Friday: { ...formState.WeekData.Friday, ...emptyDay },
        Saturday: { ...formState.WeekData.Saturday, ...emptyDay },
        Sunday: { ...formState.WeekData.Sunday, ...emptyDay },
      };
    }

    next(
      setFormState("Timesheet", state, {
        WeekData: weekData,
        DayData: { ...formState.DayData, ...emptyDay },
      } as Partial<TimesheetForm>)
    );
  });
};
