import { TimesheetType } from "../Models/TimesheetType";
import { Timesheet } from "../Models/Timesheet";
import { parseCommonDate, getDaysOfWeekNames } from "./DateHelper";

export function findTimesheetTypeById(
  timesheetTypes: TimesheetType[],
  timesheetTypeId: number
) {
  return timesheetTypes.find((tt) => tt.TimesheetTypeId === timesheetTypeId);
}

export function findTimesheetTypeByName(
  timesheetTypes: TimesheetType[],
  timesheetTypeName: string
) {
  return timesheetTypes.find(
    (tt) => tt.Description?.toLowerCase() === timesheetTypeName
  );
}

export function calculateTimesheetSumTotal(timesheet: Timesheet): number {
  return (
    timesheet.NormalQty +
    (timesheet.ExtraQty ?? 0) +
    (timesheet.OvertimeQty ?? 0) +
    (timesheet.PublicHolidayQty ?? 0)
  );
}

export function calculateTimesheetAmount(timesheet: Timesheet): number {
  return (timesheet.NormalRate ?? 0) * timesheet.NormalQty;
}

export function calculateLoggedHours(
  timesheetTypes: TimesheetType[],
  timesheets: Timesheet[]
): number {
  return timesheets.reduce((total, current) => {
    if (current.NormalRate) return total;

    return total + calculateTimesheetSumTotal(current);
  }, 0);
}

export function calculateTotalAllowance(
  timesheetTypes: TimesheetType[],
  timesheets: Timesheet[]
): number {
  return timesheets.reduce((total, current) => {
    return total + calculateTimesheetAmount(current);
  }, 0);
}

const getEmptyTotalByType = (): TotalByType => ({
  normal: 0,
  extra: 0,
  overtime: 0,
  publicHoliday: 0,
  leave: 0,
  allowance: 0,
  sumTotal: 0,
});

export type TotalByType = {
  normal: number;
  extra: number;
  overtime: number;
  publicHoliday: number;
  leave: number;
  allowance: number;
  sumTotal: number;
};

export type TotalHoursByDayAndType = {
  sunday: TotalByType;
  monday: TotalByType;
  tuesday: TotalByType;
  wednesday: TotalByType;
  thursday: TotalByType;
  friday: TotalByType;
  saturday: TotalByType;
};

export function calculateTotalHoursByDayAndType(
  timesheetTypes: TimesheetType[],
  timesheets: Timesheet[]
): TotalHoursByDayAndType {
  const defaultTotals: TotalHoursByDayAndType = {
    sunday: getEmptyTotalByType(),
    monday: getEmptyTotalByType(),
    tuesday: getEmptyTotalByType(),
    wednesday: getEmptyTotalByType(),
    thursday: getEmptyTotalByType(),
    friday: getEmptyTotalByType(),
    saturday: getEmptyTotalByType(),
  };

  return timesheets.reduce(
    (totals: TotalHoursByDayAndType, current: Timesheet) => {
      if (!current.WorkedDate) return totals;

      const timesheetType = findTimesheetTypeById(
        timesheetTypes,
        current.TimesheetTypeId
      );

      if (typeof timesheetType === "undefined") return totals;

      const day = parseCommonDate(current.WorkedDate).getDay();
      const dayKey = getDaysOfWeekNames()[day].toLowerCase();

      const totalsRecord = totals as Record<string, TotalByType>;

      totalsRecord[dayKey] = calculateTotalHoursByTypeForTimesheet(
        totalsRecord[dayKey],
        current,
        timesheetType
      );

      return totalsRecord as TotalHoursByDayAndType;
    },
    defaultTotals
  );
}

export function calculateTotalHoursByType(
  timesheetTypes: TimesheetType[],
  timesheets: Timesheet[]
): TotalByType {
  return timesheets.reduce((totals, current) => {
    const timesheetType = findTimesheetTypeById(
      timesheetTypes,
      current.TimesheetTypeId
    );

    if (typeof timesheetType === "undefined") return totals;

    return calculateTotalHoursByTypeForTimesheet(
      totals,
      current,
      timesheetType
    );
  }, getEmptyTotalByType());
}

const calculateTotalHoursByTypeForTimesheet = (
  totals: TotalByType,
  current: Timesheet,
  timesheetType: TimesheetType
): TotalByType => {
  if (timesheetType.IsLeave) {
    return {
      ...totals,
      leave: totals.leave + current.NormalQty,
      sumTotal: totals.sumTotal + current.NormalQty,
    };
  }

  if (current.NormalRate) {
    return {
      ...totals,
      allowance:
        totals.allowance + (current.NormalRate ?? 0) * current.NormalQty,
    };
  }

  return {
    ...totals,
    normal: totals.normal + current.NormalQty,
    extra: totals.extra + (current.ExtraQty ?? 0),
    overtime: totals.overtime + (current.OvertimeQty ?? 0),
    publicHoliday: totals.publicHoliday + (current.PublicHolidayQty ?? 0),
    sumTotal: totals.sumTotal + calculateTimesheetSumTotal(current),
  };
};
