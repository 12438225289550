import { ServiceProvider } from "../Models/ServiceProvider";
import { CalendarService } from "../Services/CalendarService";
import { CalendarInfo } from "../Models/CalendarInfo";

let currentStartDate: Date;
let currentEndDate: Date;

const datepickerId = "#txtDatePicker";

export function render(
  calendarInfo: CalendarInfo,
  services: ServiceProvider,
  firstRender: boolean
) {
  currentStartDate = calendarInfo.StartDate;
  currentEndDate = calendarInfo.EndDate;

  if (firstRender) {
    setupDatePicker(services.calendarService);
  } else {
    $(datepickerId).datepicker("setDate", calendarInfo.SelectedDate);

    // Need to call fill to re-render and trigger beforeShowDay
    $(datepickerId).datepicker("fill");
  }
}

function setupDatePicker(calendarService: CalendarService) {
  let datepickerVisible = false;

  const $datepicker = $(datepickerId)
    .datepicker({
      format: "dd M yyyy",
      autoclose: true,
      startDate: "01/01/1900",
      weekStart: 1,
      orientation: "right top",
      beforeShowDay: (date: Date): DatepickerBeforeShowDayResponse => ({
        classes:
          date >= currentStartDate && date <= currentEndDate ? "in-range" : "",
      }),
    })
    .on("changeDate", function (ev: any) {
      if (ev.viewMode == "days") $datepicker.datepicker("hide");

      calendarService.moveToWeek(ev.date);
    });

  // Update visibility flag, we need to do this on the next tick
  // so the click handler below is triggered before
  $datepicker
    .on("show", () => setTimeout(() => (datepickerVisible = true), 1))
    .on("hide", () => setTimeout(() => (datepickerVisible = false), 1));

  // Hide calendar on icon click if visible
  $("body").on("click", datepickerId, function () {
    if (datepickerVisible) {
      $datepicker.datepicker("hide");
    }

    $(this).toggleClass("opened", datepickerVisible);
  });
}
