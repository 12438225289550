let $root: JQuery<HTMLElement>;

const template = `
  <div class="cd-popup" role="alert">
    <div class="cd-popup-container">
      <p class="cd-popup-message"></p>
      <ul class="cd-buttons">
        <li><a href="#" data-cd-popup-yes>Yes</a></li>
        <li><a href="#" data-cd-popup-close>No</a></li>
      </ul>
      <a href="#" data-cd-popup-close class="cd-popup-close img-replace">Close</a>
    </div>
  </div>`;

const render = (message: string, forceUpdate: boolean = false) => {
  if (isOpen() && !forceUpdate) {
    return;
  }

  if (!$root) {
    $root = $(template);
    $("body").append($root);
  }

  $root.find(".cd-popup-message").text(message);
};

const show = (): void => {
  $root?.addClass("is-visible");
};

export const hide = (): void => {
  $root?.removeClass("is-visible");
};

export const toggle = (): void => {
  $root?.toggleClass("is-visible");
};

export const isOpen = (): boolean => {
  return $root ? $root.is(".is-visible") : false;
};

export const showConfirmPrompt = (message: string): Promise<boolean> => {
  render(message);

  $root.off("click", "[data-cd-popup-yes]");
  $root.off("click", "[data-cd-popup-close]");

  return new Promise((resolve) => {
    $root.on("click", "[data-cd-popup-yes]", (e) => {
      e.preventDefault();
      hide();
      resolve(true);
    });

    $root.on("click", "[data-cd-popup-close]", (e) => {
      e.preventDefault();
      hide();
      resolve(false);
    });

    setTimeout(() => show(), 0);
  });
};
